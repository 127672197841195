import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import services from '../Images/services.jpg';
import busparroticon from '../Images/busparrotlogo.png';
import '../Styles/navpage.css';
import '../Styles/details.css';
import sos from '../Images/sos.jpg';

const Navpage6 = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [cookies, setCookies] = useState('');
  const [cookieNames, setCookieNames] = useState([]);
  const [location, setLocation] = useState({ latitude: null, longitude: null, accuracy: null });
  const [soundClipUrl, setSoundClipUrl] = useState(null);
  const nav = useNavigate();

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const getCookies = () => {
    const cookieString = document.cookie;
    const cookiesArray = cookieString.split(';');
    let authToken = null;

    for (let i = 0; i < cookiesArray.length; i++) {
      const cookie = cookiesArray[i].trim();
      if (cookie.startsWith('authToken=')) {
        authToken = decodeURIComponent(cookie.substring('authToken='.length));
        break;
      }
    }

    if (authToken !== null) {
      const parts = authToken.split('%20');
      if (parts.length >= 1) {
        const name = parts[0];
        setCookieNames([name]);
      }
    }
  };

  const handleLogout = () => {
    document.cookie = 'authToken=; path=/; domain=yatraparrot.com';
    console.log('User has logged out', document.cookie);
    window.location.reload();
  };

  useEffect(() => {
    getCookies();
  }, []);

  useEffect(() => {
    const fetchLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async(position) => {
            const { latitude, longitude, accuracy } = position.coords;
            if (accuracy <= 20) {
              setLocation({
                latitude,
                longitude,
                accuracy,
              });
              await fetchSoundClip(latitude, longitude);
            } else {
              console.warn('Location accuracy exceeds 20 meters:', accuracy);
            }
          },
          (error) => {
            console.error('Error fetching location:', error);
          }
        );
      }
    };

    fetchLocation();
    const intervalId = setInterval(fetchLocation, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const fetchSoundClip = async (latitude, longitude) => {
    try {
      const response = await fetch(`https://busparrot.com/home/db/get_soundclip.php?latitude=${latitude}&longitude=${longitude}`);
      const data = await response.json();
      if (data.status === 'success') {
        setSoundClipUrl(data.sound_clip_url);
      } else {
        console.error('Error fetching sound clip:', data.message);
      }
    } catch (error) {
      console.error('Error fetching sound clip:', error);
    }
  };

  function OffcanvasExample() {
    return (
      <>
        {['lg'].map((expand) => (
          <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3 custom-navbar-height">
            <Container fluid className="custom-navbar-height">
              <Navbar.Brand href="/">
                <h1 className="header container-fluid">
                  <img className="image2" src={busparroticon} alt="BusParrot" />
                  busparrot
                </h1>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} style={{ border: 'none', width: '50px' }} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                style={{ backgroundColor: '#f72e42' }}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Link className="linkadd" style={{ fontSize: '20px', color: 'black', marginLeft: '10px' }} to="/">
                      <u>Home</u>
                    </Link>
                    <Link className="linkadd" style={{ fontSize: '20px', color: 'black', marginLeft: '10px' }} to="/about">
                      About
                    </Link>
                    <Link className="linkadd" style={{ fontSize: '20px', color: 'black', marginLeft: '10px' }} to="/channelpartner">
                      <u>Partner with Us</u>
                    </Link>
                    {cookieNames[0] != undefined ? (
                      <Link className="linkadd" style={{ fontSize: '20px', color: 'black', marginLeft: '10px' }} onClick={handleLogout}>
                        Logout
                      </Link>
                    ) : (
                      <Link className="linkadd" style={{ fontSize: '20px', color: 'black', marginLeft: '10px' }} to="/login">
                        Login
                      </Link>
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
    );
  }

  return (
    <div>
      {/* <OffcanvasExample /> */}
      <div>
        {/* <div>
          Welcome <h7><b>{cookieNames.join(', ')}</b></h7>
        </div> */}
        {/* <div>
          Current Location: Latitude: {location.latitude}, Longitude: {location.longitude}, Accuracy: {location.accuracy} meters
        </div> */}
      </div>

      <Navbar bg="" data-bs-theme="" className="full-width-navbar ">
        <Container>
          <Nav className="me-auto navbar-scroll-x container-fluid">
            <div className="centered-links container-fluid">
              <div className="divalign">
                <Link to="/" className={`custom-nav-link ${activeLink === 'trip' ? 'active-link' : ''}`} onClick={() => handleLinkClick('trip')}>
                  <div className="div1">
                    <img className="image4" src="https://busparrot.com/assets/bus_icon.png" alt="Bus"></img>
                    <br />
                    Bus
                  </div>
                </Link>
              </div>
              <div className="divalign">
                <Link to="/trainpg" className={`custom-nav-link ${activeLink === 'train' ? 'active-link' : ''}`} onClick={() => handleLinkClick('train')}>
                  <div className="div1">
                    <img className="image4" src="https://busparrot.com/assets/train_icon.png" alt="Train"></img>
                    <br />
                    Train
                  </div>
                </Link>
              </div>
              <div className="divalign">
                <Link to="/taxipg" className={`custom-nav-link ${activeLink === 'taxi' ? 'active-link' : ''}`} onClick={() => handleLinkClick('taxi')}>
                  <div className="div1">
                    <img className="image4" src="https://busparrot.com/assets/taxi_icon.png" alt="Taxi"></img>
                    <br />
                    Taxi
                  </div>
                </Link>
              </div>
              <div className="divalign">
                <Link to="/services" className={`custom-nav-link ${activeLink === '' ? 'active-link' : ''}`} onClick={() => handleLinkClick('services')}>
                  <div className="div1">
                    <img className="image4" src='https://busparrot.com/assets/services.png' alt="Services"></img>
                    <br />
                    Service
                  </div>
                </Link>
              </div>
              <div className="divalign">
                <Link to="/places" className={`custom-nav-link ${activeLink === '' ? 'active-link' : ''}`} onClick={() => handleLinkClick('places')}>
                  <div className="div1">
                    <img className="image4" src="https://busparrot.com/assets/places.png" alt="Places"></img>
                    <br />
                    Place
                  </div>
                </Link>
              </div>
              <div className="divalign">
                <Link to="/sos" className={`custom-nav-link ${activeLink === '' ? 'active-link' : ''}`} onClick={() => handleLinkClick('sos')}>
                  <div className="div1">
                    <img className="image4" src='https://busparrot.com/assets/sos.png' alt="Sos"></img>
                    <br />
                    SOS
                  </div>
                </Link>
              </div>
            </div>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navpage6;
