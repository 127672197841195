import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Navpage2 from './Navpage2';
import busparroticon from '../Images/busparrotlogo.png';
import '../Styles/buses.css';
import facebook from '../Images/facebook.png';
import linkedin from '../Images/linkedin.png';
import twitter from '../Images/twitter.png';
import youtube from '../Images/youtube.png';
import phone from '../Images/images1.jpg';
import whatsapp from '../Images/images.jpg';
import Map from './Map';
import axios from 'axios';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';
import gmap from '../Images/gmap.png';
import Cookies from 'js-cookie'; 
import sm from '../Images/streetview.png';
import Footer from './Footer';
const Taxi = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [taxiTypes, setTaxiTypes] = useState([]);
  const [selectedTaxi, setSelectedTaxi] = useState(null);
  const [data1, setData1] = useState([]);
  const [ipLocationLatitude, setIpLocationLatitude] = useState(null);
  const [ipLocationLongitude, setIpLocationLongitude] = useState(null);
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

  const navigate = useNavigate();

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    return parts.length === 2 ? parts.pop().split(';').shift() : null;
  };

  const getTaxiData = useCallback(async (taxiType) => {
    const lastLocationLatitude =  Cookies.get('latitude');
    const lastLocationLongitude = Cookies.get('longitude');
    if (lastLocationLatitude && lastLocationLongitude) {
      const apiUrl = `https://busparrot.com/pis/api/gettaxisin.php?center_lat=${lastLocationLatitude}&center_lon=${lastLocationLongitude}&radius=1000`;

      try {
        const response = await fetch(apiUrl);
        if (response.ok) {
          const data = await response.json();
          setData1(data.taxis);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      console.log('Latitude and longitude values not found in cookies.');
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const lastLocationLatitude = getCookie('lastLocationLatitude');
      const lastLocationLongitude = getCookie('lastLocationLongitude');
  
      if (!lastLocationLatitude || !lastLocationLongitude) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              console.log(latitude, "current");
              const apiUrl = `https://busparrot.com/pis/api/gettaxisin.php?center_lat=${latitude}&center_lon=${longitude}&radius=1000`;
  
              try {
                const response = await fetch(apiUrl);
                if (response.ok) {
                  const data = await response.json();
                  console.log(data);
                  const uniqueTaxiTypes = [...new Set(data.taxis.map((taxi) => taxi.taxi_type))];
                  setTaxiTypes(uniqueTaxiTypes);
                  setData1(data.taxis);
  
                  if (uniqueTaxiTypes.length > 0) {
                    getTaxiData(uniqueTaxiTypes[0]);
                  }
  
                  document.cookie = `lastLocationLatitude=${latitude}`;
                  document.cookie = `lastLocationLongitude=${longitude}`;
                }
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            },
            (error) => {
              console.error('Error getting current location:', error);
            }
          );
        } else {
          console.log('Geolocation is not supported by this browser.');
          fetchLocationByIP();
        }
      } else {
        const apiUrl = `https://busparrot.com/pis/api/gettaxisin.php?center_lat=${lastLocationLatitude}&center_lon=${lastLocationLongitude}&radius=1000`;
        console.log(lastLocationLatitude, "cookie");
  
        try {
          const response = await fetch(apiUrl);
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            const uniqueTaxiTypes = [...new Set(data.taxis.map((taxi) => taxi.taxi_type))];
  
            // Ensure 'autorikshaw' is the first and 'autocar' is the second, move the rest automatically
            const sortedTaxiTypes = ['AUTORIKSHAW', 'AUTOCAR', ...uniqueTaxiTypes.filter(type => type !== 'AUTORIKSHAW' && type !== 'AUTOCAR')];
  
            setTaxiTypes(sortedTaxiTypes);
            setData1(data.taxis);
  
            if (sortedTaxiTypes.length > 0) {
              // Set the first taxi type as the initially selected taxi type
              const initialTaxiType = sortedTaxiTypes[0];
              setActiveButton(initialTaxiType);
              setSelectedTaxi(initialTaxiType);
              getTaxiData(initialTaxiType);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
  
    const fetchLocationByIP = async () => {
      try {
        const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
        const userIpAddress = ipApiResponse.data.ip;
  
        // Use the ipapi service to get detailed location information without an API token
        const locationApiResponse = await axios.get(`https://ipapi.co/${userIpAddress}/json/`);
  
        const [latitude, longitude] = [locationApiResponse.data.latitude, locationApiResponse.data.longitude];
        console.log(latitude, "lllll");
  
        // Set the IP-based location in state
        setIpLocationLatitude(latitude);
        setIpLocationLongitude(longitude);
  
        const radius = 1000;
        getTaxiDataByLocation(latitude, longitude, radius);
  
        // Note: Don't save the IP-based location in cookies
  
        console.log(`Latitude set to: ${latitude}`);
        console.log(`Longitude set to: ${longitude}`);
      } catch (error) {
        console.error('Error fetching location by IP:', error);
      }
    };
  
    const getTaxiDataByLocation = async (lat, long, radius) => {
      try {
        const response = await fetch(`https://busparrot.com/pis/api/gettaxisin.php?center_lat=${lat}&center_lon=${long}&radius=${radius}`);
        if (response.ok) {
          const data = await response.json();
          setData1(data.taxis);
        }
      } catch (error) {
        console.error('Error fetching taxi data by location:', error);
      }
    };
  
    // Call the fetchData function initially
    fetchData();
  
    const intervalId = setInterval(() => fetchData(false), 5 * 60 * 60 * 1000);

  // Set up an interval to update the location every 30 seconds
  const locationIntervalId = setInterval(() => fetchData(),  5 * 60 * 60 * 1000);

  // Clean up the intervals when the component unmounts
  return () => {
    clearInterval(intervalId);
    clearInterval(locationIntervalId);
  };
  }, [getTaxiData]);

  const activeTabStyle = {
   
     color: 'white',
    backgroundColor: '#76c045',
    borderColor: 'black',
    borderRadius: '15px',
  };

  const icon = {
    height: '30px',
    width: '30px',
  };

  const icon1 = {
    height: '40px',
    width: '30px',
  };

  const defaultTabStyle = {
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'black',
    borderRadius: '15px',
  };

  const cardStyle = {
    width: '100%', // Adjust the width as needed
    height: 'auto', // Set height to auto for responsiveness
    float: 'left', // Align to the left side
    margin: '10px 0', // Add margin for separation
    fontFamily: 'Comfortaa',
    borderRadius: '10px',
    color: 'black',
    backgroundColor: 'white',
  
    // Responsive styles
    '@media (max-width: 768px)': {
      width: '100%', // Set width to 100% for smaller screens
      float: 'none', // Remove float for stacked cards
      marginRight: 0, // Remove right margin for stacked cards
    },
  };
  const cardRight = {
    float: 'right',
    marginTop: '10px', // Add margin for separation
  
    // Responsive styles
    '@media (max-width: 768px)': {
      float: 'none', // Remove float for stacked cards
      marginTop: '10px', // Add margin for separation
    },
  };
  
  const carddetails = {
    margin: '10px', // Add margin for separation
  
    // Responsive styles
    '@media (max-width: 768px)': {
      margin: '10px', // Add margin for separation
    },
  };

  const handleTabClick = (taxiType) => {
    setActiveButton(taxiType);
    setSelectedTaxi(taxiType);
    getTaxiData(taxiType);
  };

  const handleGetDirections = (taxi) => {
    const sourceLat = Cookies.get('latitude');
    const sourceLng = Cookies.get('longitude');
    const destinationLat = taxi.latitude;
    const destinationLng = taxi.longitude;
    const directionsURL = `https://www.google.com/maps/dir/${sourceLat},${sourceLng}/${destinationLat},${destinationLng}`;
    window.open(directionsURL);
  };

  const handleGetDirections1 = (taxi) => {
    const sourceLat = Cookies.get('latitude');
    const sourceLng = Cookies.get('longitude');
    const destinationLat = taxi.latitude;
    const destinationLng = taxi.longitude;
    const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${destinationLat},${destinationLng}&heading=0&pitch=0`;

    // Open the URL in a new tab
    window.open(streetViewURL);
  };
  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date(); 
    const feedbackData = {
      appname: 'Taxi',
      category:trip.taxi_type,
      infoid: 'Regno : ' + trip.taxi_regno
      ,  customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    }

    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
    
  
    try {
      const response = await fetch( urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          
          // Add other data fields as needed
        }),
      });
  
      console.log(response, "feedback response");


      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  const smallButtonStyle = {
    width: '40px',
  };



  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
  
    // Show a prompt to enter the message
    const userMessage = window.prompt('Enter your feedback message:');
  
    // Check if the user clicked cancel
    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }
  
    const feedbackData = {
      appname: 'Taxi',
      category:trip.taxi_type,
      infoid: 'Regno' + trip.taxi_regno
      ,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages', // Use user input or 'No Messages' if empty
      time: currentTime.toLocaleString(),
    };
  
    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
  
    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          // Add other data fields as needed
        }),
      });
  
      console.log(response, 'feedback response');
  
      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  
  const smallButtonStyle1 = {
    width: '30px', // Adjust the width value as needed
   
    
    // backgroundColor: ' #f72e42',
    marginLeft: '10px',
    marginTop:'8px'
  };
  



  const renderCards = () => {
    if (selectedTaxi) {
      const filteredData = data1.filter((taxi) => taxi.taxi_type === selectedTaxi);


      if (filteredData.length === 0) {
        // Display a message when filteredData is empty
        return (
          <div>
            <p style={{ color: 'red', fontSize: '20px', marginLeft: '30px' }}>
              No taxi data available for the selected type.
            </p>
          </div>
        );
      }
      return (
        <div className="card-container">
          {filteredData.map((taxi, index) => (
            <div className="card" style={cardStyle} key={index}>
              <div className="card-left" style={carddetails}>
                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
         
         <div onClick={() => handlemessage(taxi)}>
         <h7>Was this Correct?</h7>
           <img src='https://busparrot.com/assets/like.png' alt="Correct" style={smallButtonStyle} />
         </div>
         <div onClick={() => handlemessage1(taxi)}>
           <img src='https://busparrot.com/assets/dislike.png' alt="InCorrect" style={smallButtonStyle1} />
         </div>
       </div>
       <h3>{taxi.taxi_drivername}</h3>
        <div style={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
          <a href={`tel:${taxi.taxi_mobile}`}>
            <img src={phone} alt="Call" style={icon} />
          </a>
          <a href={`https://wa.me/${taxi.taxi_mobile}`}>
            <img src={whatsapp} alt="WhatsApp" style={icon1} />
          </a>
        </div>
                <span style={cardRight}>{parseFloat(taxi.distance).toFixed(2)} km away <br/>
                {taxi.latitude !== null && taxi.longitude !== null && (
    <React.Fragment>
                <a  onClick={() => handleGetDirections(taxi)}>
                      <img
                        src={gmap}
                        alt="Google Maps Icon"
                        style={{ width: '20px', height: '20px', marginRight: '10px' }}
                      />
                    </a><span className='go'>Go </span>
                    <a  onClick={() => handleGetDirections1(taxi)}>
                      <img
                        src={sm}
                        alt="Google Maps Icon"
                        style={{ width: '35px', height: '30px', marginRight: '10px',marginLeft:'10px' }}
                        />
                    </a>
                     </React.Fragment>
                     )}
                     </span> 
                   
                
                <p>
                  {taxi.taxi_type} - {taxi.taxi_regno}
                 
                </p>
                <p> {taxi.taxi_stand}</p>
              

                {' '}
               
              </div>
            </div>
          ))}
          <br />
        </div>
      );
    } else {
      return <p>No taxi data selected.</p>;
    }
  };



  
const getCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
        console.log(apiUrl, "kkkk");

        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            console.log('API Response:', data.stops[0]);

            if (data.stops.length > 0) {
              const firstStop = data.stops[0];

              setDefaultSelectValue({
                value: firstStop.stop_id,
                label: firstStop.stop_name,
              });
            }
          })
          
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
          fetchNearestStops(latitude,longitude );
      },
      (error) => {
        console.error('Error getting current position:', error);
        // If there's an error getting the current position, try to retrieve from cookies
        const latitudeFromCookie = Cookies.get('latitude');
        const longitudeFromCookie = Cookies.get('longitude');

        if (latitudeFromCookie && longitudeFromCookie) {
          const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitudeFromCookie}&lon=${longitudeFromCookie}&radius=2`;

          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              console.log('API Response (from cookies):', data.stops[0]);

              if (data.stops.length > 0) {
                const firstStop = data.stops[0];

                setDefaultSelectValue({
                  value: firstStop.stop_id,
                  label: firstStop.stop_name,
                });
              }
            })
            .catch((error) => {
              console.error('Error fetching data from cookies:', error);
            });
            fetchNearestStops(latitudeFromCookie, longitudeFromCookie);
        }
      }
    );
  
  } else {
    console.error('Geolocation is not available in your browser');
    // If geolocation is not available, try to retrieve from cookies
    const latitudeFromCookie = Cookies.get('latitude');
    const longitudeFromCookie = Cookies.get('longitude');

    if (latitudeFromCookie && longitudeFromCookie) {
      const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitudeFromCookie}&lon=${longitudeFromCookie}&radius=2`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          console.log('API Response (from cookies):', data.stops[0]);

          if (data.stops.length > 0) {
            const firstStop = data.stops[0];

            setDefaultSelectValue({
              value: firstStop.stop_id,
              label: firstStop.stop_name,
            });
          }
        })
        .catch((error) => {
          console.error('Error fetching data from cookies:', error);
        });
         fetchNearestStops(latitudeFromCookie, longitudeFromCookie);
    }
  }
};


const fetchNearestStops = async (latitude, longitude) => {
  try {
    console.log(latitude,longitude,"latlon");
    const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
    console.log(apiUrl,'stops')
    const response = await axios.get(apiUrl);

    console.log('API Response:', response);

    const nearestStopsData = response.data.stops.map((stop) => ({
      ID: stop.stop_id,
      stop_name: stop.stop_name,
      distance: stop.distance,
      lat: stop.stop_lat,
      lon: stop.stop_lon,
      lbcode:stop.stop_localbodycode,
      page:'taxi',
    }));

    setNearestStops(nearestStopsData);
    setDefaultSelectValue({
      value: nearestStopsData[0].ID,
      label: nearestStopsData[0].stop_name,
    });
  } catch (error) {
    console.error('Error fetching nearest stops data:', error);
  }
};


useEffect(() => {
  getCurrentLocation(); // Fetch nearest stops when the component mounts
}, []);

  return (
    <div>
      <Navpage2 />
      <div className="subbody container-fluid bg-body-tertiary mb-3 mt-2">
        <br />
        <div className="button-container ">
          {taxiTypes.map((taxiType, index) => (
            <button
              key={index}
              onClick={() => handleTabClick(taxiType)}
              className="tab"
              style={activeButton === taxiType ? activeTabStyle : defaultTabStyle}
            >
              {taxiType}
            </button>
          ))}
        </div>
        <div className="taxi-details mt-5" style={carddetails}>
          {renderCards()}
        </div>
        <div className='mapstyle'><Map /></div>
        <br />
      </div>
      <div style={{ textAlign: 'center', marginBottom: '950px' }}>
        <a href="https://m.facebook.com/100063656121677/">
          <img className="imagesocial" style={{ width: '30px' }} src={facebook} alt="" />
        </a>
        <a href="https://twitter.com/BusParrot?s=08">
          <img className="imagesocial" style={{ width: '30px' }} src={twitter} alt="" />
        </a>
        <a href="https://youtube.com/@user-mk1uk6sq2t">
          <img className="imagesocial" style={{ width: '30px' }} src={youtube} alt="" />
        </a>
        <a href="https://www.linkedin.com/company/busparrot/">
          <img className="imagesocial" style={{ width: '30px' }} src={linkedin} alt="" />
        </a>
      </div>
      <div>
  {nearestStops.length > 0 && (
    <div>
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>
     
     
    </div>
  );
};

export default Taxi;
