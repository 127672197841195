import React, { useEffect } from 'react';

const Header = () => {
  useEffect(() => {
    const cookies = document.cookie.split(';').reduce((acc, cookie) => {
      const [name, value] = cookie.trim().split('=');
      acc[name] = value;
      return acc;
    }, {});

    // Check if latitude and longitude are already in cookies
    const latitude = cookies['latitude'];
    const longitude = cookies['longitude'];

    if (!latitude || !longitude) {
      // If not present, fetch current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            // Set cookies with expiration date (7 days in this case)
            document.cookie = `latitude=${latitude}`;
            document.cookie = `longitude=${longitude}`;
              },
          (error) => {
            console.error('Error getting geolocation:', error.message);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }
  }, []);

  return null; // or return some JSX if needed
}

export default Header;
