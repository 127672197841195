import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import '../Styles/details.css';
import eyes from '../Images/eyes.png';
import { useNavigate } from 'react-router-dom';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';
import Footer from './Footer';
import Cookies from 'js-cookie';
const Tripdetails = () => {
  const location = useLocation();
  const { from, to } = location.state;
  const [tripDetails, setTripDetails] = useState(null);
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

const nav =useNavigate();
  const divStyle = {
    textAlign: 'center',
    fontFamily: 'Comfortaa, sans-serif', // Specify Comfortaa as the font
  };

  useEffect(() => {
    // Define the URL with from.value and to.value
    const apiUrl = `https://api.busparrot.com/getfromtotrips.php?from=${from.value}&to=${to.value}`;

    const fetchData = async () => {
      try {
        // Fetch trip details using Axios and await the response
        const response = await axios.get(apiUrl);
        // Set the trip details in the state
        setTripDetails(response.data);
        console.log(response.data.result)
      } catch (error) {
        console.error('Error fetching trip details:', error);
      }
    };

    fetchData();
  }, [from.value, to.value]);



  useEffect(() => {
    const fetchNearestStops = async (latitude, longitude) => {
      try {
        console.log(latitude, longitude, "latlon");
        const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
        console.log(apiUrl, 'stops');
        const response = await axios.get(apiUrl);
        console.log('API Response:', response);
        const nearestStopsData = response.data.stops.map((stop) => ({
          ID: stop.stop_id,
          stop_name: stop.stop_name,
          distance: stop.distance,
          lat: stop.stop_lat,
          lon: stop.stop_lon,
          lbcode: stop.stop_localbodycode,
          page:'trip',
        }));
        setNearestStops(nearestStopsData);
        setDefaultSelectValue({
          value: nearestStopsData[0].ID,
          label: nearestStopsData[0].stop_name,
        });
      } catch (error) {
        console.error('Error fetching nearest stops data:', error);
      }
    };
  
    const storedLatitude = Cookies.get('latitude');
    const storedLongitude = Cookies.get('longitude');
  
    console.log('Stored Latitude:', storedLatitude);
    console.log('Stored Longitude:', storedLongitude);
  
    if (storedLatitude && storedLongitude) {
      fetchNearestStops(storedLatitude, storedLongitude);
    } else {
      console.error('Latitude or longitude cookies are not set');
    }
  }, []);

  const handleclick = (trip) => {
console.log(trip.Trip_id)
    nav('/tripbusdetails', { state: { route: trip.Route, bus: trip.Bus,id:trip.Trip_id } })
  }
  

  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date(); 
    const feedbackData = {
      appname: 'Bus',
      category: 'Trip',
      infoid: "tripid : "+trip.Trip_id,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    }

    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
    
  
    try {
      const response = await fetch( urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          
          // Add other data fields as needed
        }),
      });
  
      console.log(response, "feedback response");


      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  const smallButtonStyle = {
    width: '40px', 
  };



  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
  
    // Show a prompt to enter the message
    const userMessage = window.prompt('Enter your feedback message:');
  
    // Check if the user clicked cancel
    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }
  
    const feedbackData = {
      appname: 'Bus',
      category: 'Trip',
      infoid: 'tripid' + trip.Trip_id,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages', // Use user input or 'No Messages' if empty
      time: currentTime.toLocaleString(),
    };
  
    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
  
    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          // Add other data fields as needed
        }),
      });
  
      console.log(response, 'feedback response');
  
      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  
  const smallButtonStyle1 = {
    width: '30px', // Adjust the width value as needed
   
    
    // backgroundColor: ' #f72e42',
    marginLeft: '10px',
    marginTop:'8px'
  };
  

  return (
    <div className='bg-body-tertiary mb-3'>
      <Details />
      <div style={divStyle} className="bg1">
        <h6 className='hstyle'>
          <b>{from.label}</b>
        </h6>
        <h6 className='hstyle1'><b>to</b></h6>
        <h6 className='hstyle1'>
          <b>{to.label}</b>
        </h6>
      </div>
  {tripDetails && tripDetails.success === 1 && (
    <div className="trip-list">
      {tripDetails.result.map((trip) => (
        <div className='det' key={trip.Trip_id}>
          <img className='image' src={eyes} alt="BusParrot" />
          <div className="trip-details">
          <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
         
         <div onClick={() => handlemessage(trip)}>
         <h7>Was this Correct?</h7>
           <img src='https://busparrot.com/assets/like.png' alt="Correct" style={smallButtonStyle} />
         </div>
         <div onClick={() => handlemessage1(trip)}>
           <img src='https://busparrot.com/assets/dislike.png'alt="InCorrect" style={smallButtonStyle1} />
         </div>
       </div>
            <p> <b>{trip.Arrival} Arrival</b></p>
            <h6><b> {trip.Route}</b></h6>
            <span className='sp' onClick={() => handleclick(trip)}> <b>{trip.Bus}</b></span>
            <p><b>{trip.Departure} Departure</b></p>
            {/* <button style={smallButtonStyle} onClick={() => handlemessage(trip)}>Correct</button>
        <button style={smallButtonStyle1} onClick={() => handlemessage1(trip)}>InCorrect</button> */}
      
          </div>
        </div>
      ))}
    </div>
  )}

<div>
  {nearestStops.length > 0 && (
    <div >
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>

    </div>
  );
  
 
};

export default Tripdetails;
