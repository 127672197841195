import React, { useState } from 'react';
import '../Styles/login.css';
import Navbar from 'react-bootstrap/Navbar';
import busparroticon from '../Images/busparrotlogo.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Navpage from './Navpage';
import Cookies from 'js-cookie';
import facebook from '../Images/facebook.png'
import linkedin from '../Images/linkedin.png'
import twitter from '../Images/twitter.png'
import youtube from '../Images/youtube.png'
import Loginnav from './Loginnav';
import { useNavigate } from 'react-router-dom';
const Loginpg = () => {
  const [data, setData] = useState(null);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const nav = useNavigate();

  const generateToken = () => {
    return Math.random().toString(36).substr(2);
  };

  const setonclickbtnlogin = async () => {
    const token = generateToken();
    const apiUrl = `https://api.yatraparrot.com/passenger/check_passenger_login_get.php?uid=${phone}&pw=${password}&token=${token}`;

    try {
      const response = await axios.post(apiUrl);
      console.log('Response:', response);
      if (response.data.success === 1) {
        // Set the authToken cookie here with an infinite expiration time
        Cookies.set('authToken', response.data.user_name);
        alert('Login Success');
        nav('/');
      } else {
        alert('Try again');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const isAuthenticated = () => {
    const authToken = Cookies.get('authToken');
    return !!authToken;
  };

  return (
    <div>
      <Loginnav />

      <div className="login-container">
        <div className="login-box">
          <h2>Welcome</h2>
          <br />
          <div>
            <input
              className='i2'
              type="number"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)} // Update phone state
            />
          </div>

          <br />
          <div>
            <input
              className='i2'
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Update password state
            />
          </div>

          <br />

          <button className='b1' onClick={setonclickbtnlogin}>
            Login
          </button>
          <br />
          {isAuthenticated() ? (
            <p>User is authenticated</p>
          ) : (
            <h7 className='l'>
              <Link to='/signup' className='l'>
                Create an Account
              </Link>
            </h7>
          )}
        </div>
      </div>


      <div style={{textAlign:'center',marginBottom:'750px'}}>
    <a href='https://m.facebook.com/100063656121677/'><img className="imagesocial" style={{width:'30px'}} src={facebook} alt=""></img></a>
<a href='https://twitter.com/BusParrot?s=08'><img className="imagesocial" style={{width:'30px'}} src={twitter} alt=""></img></a>
<a href='https://youtube.com/@user-mk1uk6sq2t'><img className="imagesocial" style={{width:'30px'}} src={youtube} alt=""></img></a>
<a href='https://www.linkedin.com/company/busparrot/'><img className="imagesocial" style={{width:'30px'}} src={linkedin} alt=""></img></a>
</div>


    </div>
  );
};

export default Loginpg;
