import React, { useState } from 'react';
import '../Styles/login.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import waste from '../Images/waste.jpg';
const Wastecollectionlogin = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const nav = useNavigate();

  const setonclickbtnlogin = async () => {
    // const apiUrl = `https://api.yatraparrot.com/passenger/check_passenger_login_get.php?uid=${phone}&pw=${password}&token=${generateToken()}`;
    const apiUrl = `https://yatraparrot.com/pis/api/adminlogin1.php?admin_user_name=${phone}&admin_password=${password}&appid=WS`;
  
  try {
    const response = await axios.post(apiUrl, {}, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // console.log('Response:', response.data.success);
      if (response.data.success === true) {
        Cookies.set('authToken', response.data.user_name);
        alert('Login Success');
        nav('/wsh');
      } else {
        alert('Try again');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const generateToken = () => {
    return Math.random().toString(36).substr(2);
  };

  return (
    <div className="login-container" style={{ backgroundImage: `url(${waste})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: '100vh' }}>

      <div className="login-box" style={{  marginLeft: '200px', marginRight: 'auto',width:'200px',height:'300px' }}>
        <h3><u>LOGIN PAGE</u> </h3>
        <br/>
        <input
          className='i2'
          type="number"
          placeholder="Phone"
          name="admin_user_name"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <br />
        <br/>
        <input
          className='i2'
          type="password"
          placeholder="Password"
          name="admin_password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
         <input type="hidden" name="appid" value="WS" />
        <br />
        <button className='b1' onClick={setonclickbtnlogin}>
          Login
        </button>
        <br />
        {/* <p className='l'>
          Don't have an account? <a href='/signup'>Create one</a>
        </p> */}
      </div>
    </div>
  );
};

export default Wastecollectionlogin;
