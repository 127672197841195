import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navpage from './Navpage';
import busparroticon from '../Images/busparrotlogo.png';
import '../Styles/buses.css'; // Import your custom CSS file
import facebook from '../Images/facebook.png'
import linkedin from '../Images/linkedin.png'
import twitter from '../Images/twitter.png'
import youtube from '../Images/youtube.png'
import Map from './Map';
import Footer from './Footer';
import Cookies from 'js-cookie'; 
import Select from 'react-select';
import { css } from '@emotion/react';
import axios from 'axios';
const Trip = () => {
  const [typedInput, setTypedInput] = useState('');
  const [typedInput1, setTypedInput1] = useState('');
  const [activeButton, setActiveButton] = useState(null);
  const [fromBusStand, setFromBusStand] = useState('');
  const [toBusStand, setToBusStand] = useState('');
  const [fromBusOptions, setFromBusOptions] = useState([]);
  const [toBusOptions, setToBusOptions] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);
  const [nearestStops, setNearestStops] = useState([]);
  const [lastLocationLatitude1, setLastLocationLatitude1] = useState(null);
  const [lastLocationLongitude1, setLastLocationLongitude1] = useState(null);
 
  const navigate = useNavigate(); // Initialize useNavigate
const nav =useNavigate();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '300px', // Set the width to your desired value
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      width: '300px', // Set the text color to black
    }),
  };

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);

    // Use navigate to go to the corresponding route
     switch (buttonName) {
      case 'button1':
        navigate('/');
        break;
      case 'button2':
        navigate('/trip');
        break;
      case 'button3':
        navigate('/route');
        break;
      case 'button4':
        navigate('/bus');
        break;
      default:
        break;
    }
  };
  
  const [hardCodedOptions] = useState([
    {value:"INKLAE0018" ,label: 'KOLLAPALLY(INKLAE0018)' },
    {value:"INKLAE0018" , label: 'KOLLAPALLY(INKLAE0019)' },
    { value:"INKLAE0018" ,label: 'KOLLAMPADY(INKLAG0324)' },
    {value:"INKLAE0018" , label: 'KOLLAM PADI(INKLAG0666)' },
    {value:"INKLAE0018" , label: 'KOLLELITHARA TEMPILE / VAYANASHALA(INKLAG0834)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAG0841)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAG1052)' },
    {value:"INKLAE0018" , label: 'KOLLELITHARA TEMPILE / VAYANASHALA(INKLAG1068)' },
    {value:"INKLAE0018" , label: 'KOLLAN PADI(INKLAG1254)' },
    {value:"INKLAE0018" , label: 'KOLLAN PADI(INKLAG1605)' },
    {value:"INKLAE0018" , label: 'KOLLAKODE JUNCTION(INKLAG1920)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAG2124)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAG2671)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADI(INKLAG2754)' },
    {value:"INKLAE0018" , label: 'KOLLIKKAL(INKLAG2887)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAG3195)' },
    {value:"INKLAE0018" , label: 'KOLLARA(INKLAG3314)' },
    {value:"INKLAE0018" , label: 'KOLLAKODE JUNCTION(INKLAG3584)' },
    {value:"INKLAE0018" , label: 'KOLLARA(INKLAG4010)' },
    {value:"INKLAE0018" , label: 'KOLLAKKOOD KAVALA(INKLAG4048)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAG4669)' },
    {value:"INKLAE0018" , label: 'KOLLAKKOOD KAVALA(INKLAG4698)' },
    {value:"INKLAE0018" , label: 'KOLLAM PADI(INKLAG4708)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAG5069)' },
    {value:"INKLAE0018" , label: 'KOLLIKKAL(INKLAG5472)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAG5895)' },
    {value:"INKLAE0018" , label: 'KOLLAM VALAVU(INKLAH0510)' },
    {value:"INKLAE0018" , label: 'KOLLADIPADI VAZHI(INKLAH1944)' },
    {value:"INKLAE0018" , label: 'KOLLAMPADY(INKLAH2436)' },
    {value:"INKLAE0018" , label: 'KOLLAM PADI(INKLAH2477)' },
    {value:"INKLAE0018" , label: 'KOLLATTY AMBALAM(INKLAH2597)' },
    {value:"INKLAE0018" , label: 'KOLLATTY AMBALAM(INKLAH2602)' },
    {value:"INKLAE0018" , label: 'KOLLEN PADI(INKLAH3267)' },
    {value:"INKLAE0018" , label: 'KOLLEN PADI(INKLAH3287)' },
    {value:"INKLAE0018" , label: 'KOLLAYANKADU(INKLAI0017)' },
    {value:"INKLAE0018" , label: 'KOLLAYANKAD(INKLAI0463)' },
    {value:"INKLAE0018" , label: 'KOLLENGODE TOWN(INKLAI0479)' },
    {value:"INKLAE0018" , label: 'KOLLENGODE BUS STAND(INKLAI0480)' },
    {value:"INKLAE0018" , label: 'KOLLATHARA(INKLAI0653)' },
    {value:"INKLAE0018" , label: 'KOLLENGODE BUS STAND(INKLAI0857)' },
    {value:"INKLAE0018" , label: 'KOLLAYANKADE(INKLAI1245)' },
    {value:"INKLAE0018" , label: 'KOLLAYAMKAADU(INKLAI1267)' },
    {value:"INKLAE0018" , label: 'KOLLENGODE(INKLAI1298)' },
    {value:"INKLAE0018" , label: 'METTUPALAYAM KOLLENGODE(INKLAI1339)' },
    {value:"INKLAE0018" , label: 'METTUPALAYAM KOLLENGODE(INKLAI1387)' },
    {value:"INKLAE0018" , label: 'KOLLENKODE THANNEERPANTHAL(INKLAI1420)' },
    {value:"INKLAE0018" , label: 'KOLLENGODE TOWN(INKLAI1670)' },
    {value:"INKLAE0018" , label: 'KOLLENGODE POLACHI ROAD(INKLAI2070)' },
    {value:"INKLAE0018" , label: 'KOLLENKODE THANNEERPANTHAL(INKLAI2361)' },
    {value:"INKLAE0018" , label: 'KOLLENGODE(INKLAI3913)' },
    {value:"INKLAE0018" , label: 'KOLLAN PADI PONNANI(INKLAJ0058)' },
    { value: "INKLAI0004", label: 'PALAKKAD MUNICIPAL STAND(INKLAI0004)' },
    { value: "INKLAI0621", label: 'CHAKKANTHARA PALAKKAD(INKLAI0621)' },
    { value: "INKLAI0682", label: 'PALAKKAD TOWN STAND(INKLAI0682)' },
    { value: "INKLAI0858", label: 'KSRTC PALAKKAD(INKLAI0858)' },
    { value: "INKLAI1363", label: 'PALAKKAD STADIUM BUS STATION DLT(INKLAI1363)' },
    { value: "INKLAI1465", label: 'PALAKKAD MEDICAL COLLEGE(INKLAI1465)' },
    { value: "INKLAI2090", label: 'PALAKKAD(INKLAI2090)' },
    { value: "INKLAI3658", label: 'GOVT. MEDICAL COLLEGE PALAKKAD(INKLAI3658)' },
  
    // Add more hardcoded options as needed
  ]);


  const handleInputChange = (inputValue) => {
    setTypedInput(inputValue); // Update the typed input text
    console.log('Typed Input:', inputValue); // Log the typed input text to the console
  };
 
 



  const fetchFromBusStops = (inputValue) => {
    // Fetch "From Bus Stand" options based on inputValue
    // Replace the URL with your actual API endpoint
    fetch(`https://api.busparrot.com/searchstop.php?term=${inputValue}`)
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((stop) => ({
          value: stop.id,
          label: stop.value,
        }));
        setFromBusOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching "From Bus Stand" data:', error);
      });
  };

  const fetchToBusStops = (inputValue) => {
    // Fetch "To Bus Stand" options based on inputValue
    // Replace the URL with your actual API endpoint
    fetch(`https://api.busparrot.com/searchstop.php?term=${inputValue}`)
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((stop) => ({
          value: stop.id,
          label: stop.value,
        }));
        setToBusOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching "To Bus Stand" data:', error);
      });
  };

  const handleSearch = () => {
    // Replace with your search logic using the selected values
    if (fromBusStand && toBusStand) {
      console.log('Searching for trip from:', fromBusStand);
      console.log('Searching for trip to:', toBusStand);
      navigate('/tripdetails', { state: { from: fromBusStand, to: toBusStand } });
    } else if (defaultSelectValue!=null) {
      navigate('/tripdetails', { state: { from: defaultSelectValue, to: toBusStand } });
      console.log('Please select both bus stands to search for a trip.');
    }
  };
  const getCurrentLocation = async () => {
    try {
      // Case 1: Check Location from Cookies
      const storedLatitude = Cookies.get('latitude');
      const storedLongitude = Cookies.get('longitude');
  
      if (storedLatitude && storedLongitude) {
        console.log('Location from Cookies:', storedLatitude, storedLongitude);
        setLastLocationLatitude1(storedLatitude);
        setLastLocationLongitude1(storedLongitude);
        await fetchNearestStops(storedLatitude, storedLongitude);
      }
      // else {
      //   // Case 2: Fetch Location from Server
      //   const response = await axios.get('https://yatraparrot.com/homephp/checkqrdb.php');
      //   console.log('Response from PHP file:', response.data);
      //   const data = response.data;
      //   console.log(data, 'data');
      //   let matchingEntry;
  
      //   if (Array.isArray(data)) {
      //     matchingEntry = data.find(entry => entry.qrslno === qrslnoFromUrl);
      //   }
  
      //   if (matchingEntry) {
      //     const stopLat = matchingEntry.stop_lat;
      //     const stopLon = matchingEntry.stop_lon;
      //     console.log(stopLat, 'lat');
      //     setStopLat(stopLat);
      //     setStopLon(stopLon);
      //     setLastLocationLatitude1(stopLat);
      //     setLastLocationLongitude1(stopLon);
      //     const expirationDate = new Date();
      //     expirationDate.setFullYear(expirationDate.getFullYear() + 10);
  
      //     Cookies.set('latitude', stopLat.toString(), { expires: expirationDate });
      //     Cookies.set('longitude', stopLon.toString(), { expires: expirationDate });
  
      //     await fetchNearestStops(stopLat, stopLon);
      //   } 
        
      //   else {
      //     console.error('No matching entry found in server data');
      //   }
      // }
    } catch (error) {
      console.error('Error fetching location data:', error);
      // Handle the error case here if needed
    }
  };
 


  const fetchNearestStops = async (latitude, longitude) => {
    try {
      console.log(latitude,longitude,"latlon");
      const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
      console.log(apiUrl,'stops')
      const response = await axios.get(apiUrl);
  
      console.log('API Response:', response);
  
      const nearestStopsData = response.data.stops.map((stop) => ({
        ID: stop.stop_id,
        stop_name: stop.stop_name,
        distance: stop.distance,
        lat: stop.stop_lat,
        lon: stop.stop_lon,
        lbcode:stop.stop_localbodycode,
        page:'trip',
      }));
  
      setNearestStops(nearestStopsData);
      setDefaultSelectValue({
        value: nearestStopsData[0].ID,
        label: nearestStopsData[0].stop_name,
      });
    } catch (error) {
      console.error('Error fetching nearest stops data:', error);
    }
  };
  useEffect(() => {
    getCurrentLocation(); // Fetch nearest stops when the component mounts
  }, []);


  return (
    <div>
   <Navpage/>

   <div className='subbody container-fluid'>

    
   <div className="button-container">
        <button
            className={`button ${activeButton === 'button1' ? 'active' : ''}`}
            onClick={() => handleClick('button1')}
           
          >
            Stop
          </button>
          <button
            className={`button ${activeButton === 'button2' ? 'active' : ''}`}
            onClick={() => handleClick('button2')}
            style={{ backgroundColor: '#76c045' }}
          >
            Trip
          </button>
          <button
            className={`button ${activeButton === 'button3' ? 'active' : ''}`}
            onClick={() => handleClick('button3')}
          >
            Route
          </button>
          
          <button
            className={`button ${activeButton === 'button4' ? 'active' : ''}`}
            onClick={() => handleClick('button4')}
          >
            Bus
          </button>
        </div>

        <div className="trip-container">
          <h2>Search Trip</h2>
          <div className="input-container">
            {/* Searchable select box for "From Bus Stand" */}
            <Select
          
            value={fromBusStand || defaultSelectValue}
              onChange={(value) => setFromBusStand(value)
                // setTypedInput(value ? defaultSelectValue : ''); // Update typedInput with label
                // fetchStops(value ? defaultSelectValue : '');
              
              }
              onInputChange={(inputValue) => fetchFromBusStops(inputValue)}
              options={fromBusOptions.concat(hardCodedOptions)}
              placeholder="From Bus Stand"
              styles={customStyles}
            />
          </div>
          <div className="input-container">
            <Select
          
              value={toBusStand}
              onChange={(value) => setToBusStand(value)}
              onInputChange={(inputValue) => fetchToBusStops(inputValue)}
              options={toBusOptions.concat(hardCodedOptions)}
              placeholder="To Bus Stand"
              styles={customStyles}
            />
          </div>



        <div className="input-container">
          <button className="search-button" onClick={handleSearch}>Search</button>
        </div>
      </div>
  
    </div>
       <div className='mapstyle'><Map/></div>
    <br/>
    <div style={{textAlign:'center',marginBottom:'750px'}}>
    <a href='https://m.facebook.com/100063656121677/'><img className="imagesocial" style={{width:'30px'}} src={facebook} alt=""></img></a>
<a href='https://twitter.com/BusParrot?s=08'><img className="imagesocial" style={{width:'30px'}} src={twitter} alt=""></img></a>
<a href='https://youtube.com/@user-mk1uk6sq2t'><img className="imagesocial" style={{width:'30px'}} src={youtube} alt=""></img></a>
<a href='https://www.linkedin.com/company/busparrot/'><img className="imagesocial" style={{width:'30px'}} src={linkedin} alt=""></img></a>
</div>
    <div>
  {nearestStops.length > 0 && (
    <div>
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>
 

    </div>
  );
}

export default Trip;
