import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import StartIcon from '../Images/start.png';
import StopIcon from '../Images/stop.png';
import redparrot from '../Images/redParrotsmall.ico';
import place1 from '../Images/place.png';
import * as turf from '@turf/turf';

// Helper function to get a cookie by name
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const Map = ({ currentLatitude, currentLongitude }) => {
  const [position, setPosition] = useState([currentLatitude, currentLongitude]);
  const [centerLatLng, setCenterLatLng] = useState({ lat: null, lng: null });
  const mapRef = useRef(null);
  const stopsLayer = useRef(L.layerGroup());
  const placesLayer = useRef(L.layerGroup());
  const routingControlRef = useRef(null);
  const centerMarkerRef = useRef(null); // Reference to the center marker
  const [nearestStartStopName, setNearestStartStopName] = useState('');
  const [nearestFinishStopName, setNearestFinishStopName] = useState('');
  const [upcomingTrips, setUpcomingTrips] = useState([]);

  useEffect(() => {
    const cookieLat = getCookie('latitude');
    const cookieLng = getCookie('longitude');

    if (cookieLat && cookieLng) {
      setPosition([parseFloat(cookieLat), parseFloat(cookieLng)]);
    } else if (currentLatitude && currentLongitude) {
      setPosition([currentLatitude, currentLongitude]);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const { latitude, longitude } = pos.coords;
        setPosition([latitude, longitude]);
        document.cookie = `latitude=${latitude};path=/`;
        document.cookie = `longitude=${longitude};path=/`;
        if (mapRef.current) {
          mapRef.current.panTo([latitude, longitude]);
        }
      });
    }
  }, [currentLatitude, currentLongitude]);

  const fetchTripData = async (startStop, finishStop) => {
    try {
      if (startStop && finishStop) {
        const response = await fetch(
          `https://api.busparrot.com/getfromtotrips.php?from=${encodeURIComponent(
            startStop.stop_id
          )}&to=${encodeURIComponent(finishStop.stop_id)}`
        );
        if (response.ok) {
          const tripData = await response.json();
          console.log("Trip Data:", tripData);

          const currentDate = new Date();
          const currentHours = currentDate.getHours();
          const currentMinutes = currentDate.getMinutes();
          const currentTimeInMinutes = currentHours * 60 + currentMinutes;

          const filteredTrips = tripData.result.filter((trip) => {
            const [arrivalHour, arrivalMinute] = trip.Arrival.split(":");
            const arrivalTimeInMinutes =
              parseInt(arrivalHour) * 60 + parseInt(arrivalMinute);

            const timeDifference = arrivalTimeInMinutes - currentTimeInMinutes;

            return timeDifference >= 0 && timeDifference <= 30;
          });

          console.log("Upcoming Trips:", filteredTrips);
          if (filteredTrips.length > 0) {
            setUpcomingTrips([filteredTrips[0]]);
          } else {
            setUpcomingTrips([]); // Set to an empty array if no trips found
          } 
        } else {
          console.error("Failed to fetch trip data");
        }
      }
    } catch (error) {
      console.error("Error fetching trip data:", error);
    }
  };

  useEffect(() => {
    if (position[0] && position[1]) {
      if (!mapRef.current) {
        // Initialize the map
        const map = L.map('map').setView(position, 11); // or a higher number for closer view

        mapRef.current = map;
  
        // Add tile layer
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);
  
        console.log("Current Zoom Level: ", map.getZoom());
        // Define custom icons for start and stop
        const startIcon = L.icon({
          iconUrl: StartIcon,
          iconSize: [64, 64],
        });
  
        const stopIcon = L.icon({
          iconUrl: StopIcon,
          iconSize: [64, 64],
        });
  
        // Add the start marker (static, at initial position)
        L.marker([position[0], position[1]], { icon: startIcon }).addTo(map);
  
        // Add the stop marker at the map center (this will move)
        const centerMarker = L.marker(map.getCenter(), {
          icon: stopIcon,
          interactive: false,
        }).addTo(map);
  
        centerMarkerRef.current = centerMarker;
  
        // Initialize the routing control
        routingControlRef.current = L.Routing.control({
          waypoints: [
            L.latLng(position[0], position[1]), // Start point
            L.latLng(map.getCenter()), // Initial center as destination
          ],
          routeWhileDragging: true,
        }).addTo(map);
  
        let isDragging = false;
  
        // Listen for dragstart to prevent updating during dragging
        map.on('dragstart', () => {
          isDragging = true;
        });
  
        // Update the destination marker continuously as the map is dragged
        map.on('drag', () => {
          const center = map.getCenter(); // Get the new map center
          centerMarker.setLatLng(center); // Move the stop marker to the new center
          setCenterLatLng(center); // Update the centerLatLng state
        });
  
        // Handle the dragend event to finalize the changes
        map.on('dragend', async () => {
          isDragging = false;
          const center = map.getCenter(); // Get the new map center
  
          centerMarker.setLatLng(center); // Move the stop marker to the new center
          setCenterLatLng(center); // Update the centerLatLng state
  
          // const zoomLevel = map.getZoom();
          // if (zoomLevel < 10) {
          //   map.setZoom(zoomLevel + 1); // This increases zoom, adjust this as per your needs
          //   console.log("entered : ");
          // }
          let currentZoom = map.getZoom();
          const minZoom = map.getMinZoom(); // Get the minimum zoom level
        
          if (currentZoom > minZoom) {
            map.setZoom(currentZoom - 1); // Decrease the zoom if it's not already at the minimum
          } else if (currentZoom === minZoom) {
            console.log("Reached minimum zoom level");
          }

  
          // Fetch stops and places around the new center
          try {
            const [stopsResponse, placesResponse] = await Promise.all([
              fetch(`https://busparrot.com/pis/api/v1/getstopsaround.php?center_lat=${center.lat}&center_lng=${center.lng}&radius=10`),
              fetch(`https://busparrot.com/pis/api/v1/getplacesaround.php?center_lat=${center.lat}&center_lng=${center.lng}&radius=10`),
            ]);
  
            if (stopsResponse.ok && placesResponse.ok) {
              const [stopsData, placesData] = await Promise.all([
                stopsResponse.json(),
                placesResponse.json(),
              ]);
  
              stopsLayer.current.clearLayers();
              placesLayer.current.clearLayers();
  
              // Add stop markers
              stopsData.stops.forEach((stop) => {
                L.marker([stop.stop_lat, stop.stop_lon], {
                  icon: L.icon({
                    iconUrl: redparrot,
                    iconSize: [40, 40],
                  }),
                }).bindPopup(stop.stop_name).addTo(stopsLayer.current);
              });
  
              // Add place markers
              placesData.places.forEach((place) => {
                L.marker([place.lat, place.lon], {
                  icon: L.icon({
                    iconUrl: place1,
                    iconSize: [20, 20],
                  }),
                }).bindPopup(place.title).addTo(placesLayer.current);
              });
  
              stopsLayer.current.addTo(mapRef.current);
              placesLayer.current.addTo(mapRef.current);
            } else {
              console.error('Failed to fetch stops or places');
            }
          } catch (error) {
            console.error('Error fetching stops or places:', error);
          }
  
          // Fetch nearest stops for routing
          try {
            const response = await fetch(`https://api.busparrot.com/getneareststops.php?lat=${center.lat}&lon=${center.lng}&radius=2`);
            if (!response.ok) {
              throw new Error('Failed to fetch nearest stops');
            }
  
            const nearestStops = await response.json();
            const stopsArray = nearestStops.stops;
  
            const startCoords = turf.point([position[1], position[0]]);
            const finishCoords = turf.point([center.lng, center.lat]);
  
            let nearestStartStop = null;
            let nearestFinishStop = null;
            let minStartDistance = Infinity;
            let minFinishDistance = Infinity;
  
            stopsArray.forEach((stop) => {
              const stopCoords = turf.point([stop.stop_lon, stop.stop_lat]);
  
              // Calculate the distance to the start position
              const distanceToStart = turf.distance(startCoords, stopCoords);
              if (distanceToStart < minStartDistance) {
                minStartDistance = distanceToStart;
                nearestStartStop = stop;
              }
  
              // Calculate the distance to the finish position (center of the map)
              const distanceToFinish = turf.distance(finishCoords, stopCoords);
              if (distanceToFinish < minFinishDistance) {
                minFinishDistance = distanceToFinish;
                nearestFinishStop = stop;
              }
            });
  
            setNearestStartStopName(nearestStartStop.stop_name);
            setNearestFinishStopName(nearestFinishStop.stop_name);
  
            // Fetch trip data for the new start and finish stops
            await fetchTripData(nearestStartStop, nearestFinishStop);
          } catch (error) {
            console.error('Error fetching nearest stops:', error);
          }
  
          // Update the routing waypoints after dragging ends
          if (routingControlRef.current) {
            routingControlRef.current.setWaypoints([
              L.latLng(position[0], position[1]), // Start point
              L.latLng(center.lat, center.lng), // New destination
            ]);
          }
        });
  
        // Handle map movement but without dragging
        map.on('move', () => {
          if (!isDragging) {
            const center = map.getCenter();
            centerMarker.setLatLng(center);  // Move marker to the current center
            setCenterLatLng(center); // Update the state for the center
          }
        });
      }
    }
  }, [position]);
  
  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  return (
    <div>
       <div id="map" style={mapContainerStyle} />
      
      {/* Display the upcoming trips */}
      <div>
        <h2>Upcoming Trips</h2>
        {upcomingTrips.length > 0 ? (
          <ul>
            {upcomingTrips.map((trip, index) => (
              <li key={index}>
                <p>
                {trip.Bus} Bus arrives {trip.Arrival}, 
                  takes off {trip.Departure} from  {trip.Route}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No upcoming trips within the next 30 minutes.</p>


        )}
      </div>
    </div>
  );
};
export default Map;
