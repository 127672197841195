 import React, { useState, useEffect } from 'react';
import Navpage3 from './Navpage3';
import gmap from '../Images/gmap.png';
import phone  from '../Images/images1.jpg';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';
import facebook from '../Images/facebook.png'
import linkedin from '../Images/linkedin.png'
import twitter from '../Images/twitter.png'
import youtube from '../Images/youtube.png'
import axios from 'axios';
import Map from './Map';
import Cookies from 'js-cookie'; 
import sm from '../Images/streetview.png';
import Footer from './Footer';
const Places = () => {
  const [places, setPlaces] = useState([]);
  const [lastLocationLatitude1, setLastLocationLatitude1] = useState(null);
  const [lastLocationLongitude1, setLastLocationLongitude1] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [lastLocationLatitude, setLastLocationLatitude] = useState(null);
  const [lastLocationLongitude, setLastLocationLongitude] = useState(null);
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    };
  
    const lastLocationLatitude =  Cookies.get('latitude');
    const lastLocationLongitude = Cookies.get('longitude');
  
    if (lastLocationLatitude && lastLocationLongitude) {
      // Use coordinates from cookies
      setLastLocationLatitude1(lastLocationLatitude);
      setLastLocationLongitude1(lastLocationLongitude);
      const radius = 10; // Set your desired radius
      fetchData(lastLocationLatitude, lastLocationLongitude, radius);
      fetchNearestStops(lastLocationLatitude,lastLocationLongitude );
    } else {
      // If latitude and longitude not found in cookies, get the current location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLastLocationLatitude1(latitude);
            setLastLocationLongitude1(longitude);
            const radius = 10;
            fetchData(latitude, longitude, radius);
            fetchNearestStops(latitude,longitude );
    
            // Save the current location in cookies
            document.cookie = `lastLocationLatitude=${latitude}`;
            document.cookie = `lastLocationLongitude=${longitude}`;
            console.log(`Latitude cookie set to: ${latitude}`);
            console.log(`Longitude cookie set to: ${longitude}`);
          },
          (error) => {
            // If getting current location fails, fetch location using IP address
            console.error('Error getting current location:', error);
            fetchLocationByIP();
          }
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
        fetchLocationByIP();
      }
    
       const fetchLocationByIP = async () => {
        try {
          const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
          const userIpAddress = ipApiResponse.data.ip;

          // Use the ipapi service to get detailed location information without an API token
          const locationApiResponse = await axios.get(`https://ipapi.co/${userIpAddress}/json/`);
          
          const [latitude, longitude] = [locationApiResponse.data.latitude, locationApiResponse.data.longitude];
          console.log(latitude, "lllll");
          setLastLocationLatitude(latitude);
          setLastLocationLongitude(longitude);

          const radius = 10;
          fetchData(latitude, longitude, radius);
          fetchNearestStops(latitude,longitude );
          console.log(`Latitude set to: ${latitude}`);
          console.log(`Longitude set to: ${longitude}`);
        } catch (error) {
          console.error('Error fetching location by IP:', error);
        }
      };
      
    }
    



    
    // function getCookie(name) {
    //   const value = `; ${document.cookie}`;
    //   const parts = value.split(`; ${name}=`);
    //   if (parts.length === 2) return parts.pop().split(';').shift();
    //   return null;
    // }

    function fetchData(latitude, longitude) {
      const apiUrl = `https://busparrot.com/pis/api/getplacesin.php?center_lat=${latitude}&center_lon=${longitude}&radius=10`;

      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          const uniqueCategories = Array.from(new Set(data.result.map((place) => place.category)));
          setCategories(uniqueCategories); // Set categories data in state
          setPlaces(data.result); // Set places data in state
          // Set the first category as the selected category when the component mounts
          if (uniqueCategories.length > 0) {
            setSelectedCategory(uniqueCategories[0]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, []); 





  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredPlaces = selectedCategory
    ? places.filter((place) => place.category === selectedCategory)
    : places;



  const handleGetDirections = (place) => {
    // Assuming you have the latitude and longitude values for the source and destination
    const destinationLat =place.lat;
    const destinationLng = place.lon;

    // Create the Google Maps directions URL
    const directionsURL = `https://www.google.com/maps/dir/${lastLocationLatitude1},${lastLocationLongitude1}/${destinationLat},${destinationLng}`;

    // Open the URL in a new tab
    window.open(directionsURL);
  };
  const handleGetDirections1 = (place) => {
    // Assuming you have the latitude and longitude values for the source and destination
    const destinationLat =place.lat;
    const destinationLng = place.lon;

    // Create the Google Maps directions URL
    const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${destinationLat},${destinationLng}&heading=0&pitch=0`;

    // Open the URL in a new tab
     window.open(streetViewURL);
  };

 
  const cardStyle = {
    width: '100%', // Adjust the width as needed
    height: 'auto', // Set height to auto for responsiveness
    float: 'left', // Align to the left side
    margin: '10px 0', // Add margin for separation
    fontFamily: 'Comfortaa',
    borderRadius: '10px',
    color: 'black',
    backgroundColor: 'white',
  
    // Responsive styles
    '@media (max-width: 768px)': {
      width: '100%', // Set width to 100% for smaller screens
      float: 'none', // Remove float for stacked cards
      marginRight: 0, // Remove right margin for stacked cards
    },
  };
  const cardRight = {
    float: 'right',
    marginTop: '10px', // Add margin for separation
  
    // Responsive styles
    '@media (max-width: 768px)': {
      float: 'none', // Remove float for stacked cards
      marginTop: '10px', // Add margin for separation
    },
  };
  
  const carddetails = {
    margin: '10px', // Add margin for separation
  
    // Responsive styles
    '@media (max-width: 768px)': {
      margin: '10px', // Add margin for separation
    },
  };


  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date(); 
    const feedbackData = {
      appname: 'Places',
      category: 'place',
      infoid: 'placeid : ' + trip.id,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    }

    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
    
  
    try {
      const response = await fetch( urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          
          // Add other data fields as needed
        }),
      });
  
      console.log(response, "feedback response");


      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  const smallButtonStyle = {
    width: '40px',
  };



  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
  
    // Show a prompt to enter the message
    const userMessage = window.prompt('Enter your feedback message:');
  
    // Check if the user clicked cancel
    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }
  
    const feedbackData = {
      appname: 'Places',
      category: 'place',
      infoid: 'placeid' + trip.id,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages', // Use user input or 'No Messages' if empty
      time: currentTime.toLocaleString(),
    };
  
    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
  
    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({
          // Add your data here
          // Add other data fields as needed
        }),
      });
  
      console.log(response, 'feedback response');
  
      if (response.status === 200) {
        // Display an alert when feedback is successfully posted
        window.alert('Feedback posted successfully!');
      } else {
        // Display an alert for any other response status
        window.alert('Failed to post feedback. Please try again.');
      }
      // Handle the response accordingly
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };
  
  const smallButtonStyle1 = {
    width: '30px', // Adjust the width value as needed
   
    
    // backgroundColor: ' #f72e42',
    marginLeft: '10px',
    marginTop:'8px'
  };
  
  const tabLinksContainerStyle = {
    overflowX: 'auto',
    display: 'flex', // Ensure tabs are in a single line
    width: '100%', // Set a fixed width to enable scrolling
    color: 'black',
    marginLeft: '50px',
    paddingBottom: '10px', // Add some space at the bottom
  };
  // const displayLocationInfo = () => {
  //   return (
  //     <div>
  //       <h4>Location Information</h4>
  //       <p>
  //         <strong>Last Location Latitude:</strong> {lastLocationLatitude1}
  //       </p>
  //       <p>
  //         <strong>Last Location Longitude:</strong> {lastLocationLongitude1}
  //       </p>
  //     </div>
  //   );
  // };
  const renderPlaces = () => {
    if (filteredPlaces.length === 0) {
      return (
        <div>
          <p style={{ color: 'red', fontSize: '20px', marginLeft: '30px'  }}>
            The public transport system in your area is not digitized yet. Please contact busparrot through your local body to digitize.</p>
        </div>
      );
    }}
  
const fetchNearestStops = async (latitude, longitude) => {
  try {
    console.log(latitude,longitude,"latlon");
    const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
    console.log(apiUrl,'stops')
    const response = await axios.get(apiUrl);

    console.log('API Response:', response);

    const nearestStopsData = response.data.stops.map((stop) => ({
      ID: stop.stop_id,
      stop_name: stop.stop_name,
      distance: stop.distance,
      lat: stop.stop_lat,
      lon: stop.stop_lon,
      lbcode:stop.stop_localbodycode,
      page:'place',
    }));

    setNearestStops(nearestStopsData);
    setDefaultSelectValue({
      value: nearestStopsData[0].ID,
      label: nearestStopsData[0].stop_name,
    });
  } catch (error) {
    console.error('Error fetching nearest stops data:', error);
  }
};


  
  return (
    <div>
    <Navpage3 />
<br/>
<br/>

    <div className="tab-container">
      <div className="tab-links" style={tabLinksContainerStyle}>
        {categories.map((category) => (
          <div
            key={category}
            className={`tab-link ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => handleCategoryClick(category)}
            style={{
              whiteSpace: 'nowrap',
              padding: '5px',
              backgroundColor: '#76c045',
              color: 'white',
              border: '1px solid white',
              borderRadius: '15px',
              marginRight: '10px',
            }}
          >
            {category.toUpperCase()}
          </div>
        ))}
      </div>
    </div>

    <div className="tab-content">
      {categories.map((category) => (
        <div
          key={category}
          className={`tab-pane ${selectedCategory === category ? 'active' : ''}`}
        >
         
          <div className="button-container">
            <div className="card-container">
              {filteredPlaces.map((place) => (
                <div className="card" style={cardStyle} key={place.id}>
                  <div className="place-card container-fluid" style={carddetails} key={place.id}>
                  <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
         
         <div onClick={() => handlemessage(place)}>
         <h7>Was this Correct?</h7>
           <img src='https://busparrot.com/assets/like.png' alt="Correct" style={smallButtonStyle} />
         </div>
         <div onClick={() => handlemessage1(place)}>
           <img src='https://busparrot.com/assets/dislike.png' alt="InCorrect" style={smallButtonStyle1} />
         </div>
       </div>
                    <h3>{place.title}</h3>
               
                    <p>
                      {place.category}
                      <span style={cardRight}>
                        {parseFloat(place.distance).toFixed(2)} km away
                        <br />
                        <a onClick={() => handleGetDirections(place)}>
                          <img
                            src={gmap}
                            alt="Google Maps Icon"
                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                          />
                        </a>
                        <span className="go">Go </span>
                        <a onClick={() => handleGetDirections1(place)}>
                          <img
                            src={sm}
                            alt="Google Maps Icon"
                            style={{ width: '35px', height: '30px', marginRight: '10px',marginLeft:'10px' }}
                        />
                        </a>
                      </span>
                    </p>

                    {place.contact && (
                      <p>
                        <a href={`tel:${place.contact}`}>
                          <img
                            src={phone}
                            alt="Phone Icon"
                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                          />
                        
                        </a>
                      </p>
                    )}

                  
                    <br />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
     <div className="mapstyle">
      <Map />
    </div>
      <div style={{ textAlign: 'center', marginBottom: '750px' }}>
      <a href='https://m.facebook.com/100063656121677/'>
        <img className="imagesocial" style={{ width: '30px' }} src={facebook} alt="" />
      </a>
      <a href='https://twitter.com/BusParrot?s=08'>
        <img className="imagesocial" style={{ width: '30px' }} src={twitter} alt="" />
      </a>
      <a href='https://youtube.com/@user-mk1uk6sq2t'>
        <img className="imagesocial" style={{ width: '30px' }} src={youtube} alt="" />
      </a>
      <a href='https://www.linkedin.com/company/busparrot/'>
        <img className="imagesocial" style={{ width: '30px' }} src={linkedin} alt="" />
      </a>
    </div>
    <div>
  {nearestStops.length > 0 && (
    <div>
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>
    {renderPlaces()}
    {/* <div className="mapstyle">
      <Map />
    </div> */}
    <br />
    {/* <div style={{ textAlign: 'center', marginBottom: '750px' }}>
      <a href='https://m.facebook.com/100063656121677/'>
        <img className="imagesocial" style={{ width: '30px' }} src={facebook} alt="" />
      </a>
      <a href='https://twitter.com/BusParrot?s=08'>
        <img className="imagesocial" style={{ width: '30px' }} src={twitter} alt="" />
      </a>
      <a href='https://youtube.com/@user-mk1uk6sq2t'>
        <img className="imagesocial" style={{ width: '30px' }} src={youtube} alt="" />
      </a>
      <a href='https://www.linkedin.com/company/busparrot/'>
        <img className="imagesocial" style={{ width: '30px' }} src={linkedin} alt="" />
      </a>
    </div> */}
  </div>
);
};

export default Places;
