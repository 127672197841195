import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom';
import '../Styles/navpage.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'; // Import NavDropdown from React Bootstrap
import Offcanvas from 'react-bootstrap/Offcanvas';
import services from '../Images/services.jpg';
import busparroticon from '../Images/busparrotlogo.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'; 
const Aboutnav = () => {
  const [cookieNames, setCookieNames] = useState([]);
  const getCookies = () => {
    const cookieString = document.cookie;
    const cookiesArray = cookieString.split(';');
    let authToken = null;
  
    for (let i = 0; i < cookiesArray.length; i++) {
      const cookie = cookiesArray[i].trim();
      if (cookie.startsWith('authToken=')) {
        authToken = decodeURIComponent(cookie.substring('authToken='.length));
        break; // Stop searching once authToken is found
      }
    }
  
    if (authToken !== null) {
      // authToken is found, and you can use it.
      console.log('authToken:', authToken);
  
      // Extract "Satheesh" from authToken
      const parts = authToken.split('%20'); // Split by '%20' which represents space in URL encoding
      if (parts.length >= 1) {
        const name = parts[0];
        console.log('Name:', name);
  
        // Set the names as an array
        setCookieNames([name]);
      } else {
        console.log('Name not found in authToken.');
      }
    } else {
      // authToken not found in cookies.
      console.log('authToken not found in cookies.');
    }
  };
  const handleLogout = () => {
    // Delete the cookie with no specific expiration, effectively making it a session cookie
    document.cookie = 'authToken=; path=/; domain=yatraparrot.com';
    // Log a message to the console
    console.log('User has logged out',document.cookie);
    // Reload the page to reflect the change (you can use other state management techniques)
    window.location.reload();
  };

  useEffect(() => {
    getCookies(); // Get cookies when the component mounts
  }, []);

    function OffcanvasExample() {
        return (
          <>
            {['lg'].map((expand) => (
              <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
                <Container fluid>
                  <Navbar.Brand to="/"><h1 className='header container-fluid'>
        <img className='image2' src={busparroticon} alt="BusParrot"  />
        busparrot</h1></Navbar.Brand>
                  <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}  style={{ border: 'none',width:'50px' }} />
                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    style={{backgroundColor:'#f72e42'}}
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                        
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body >
                      <Nav className="justify-content-end flex-grow-1 pe-3">
                      <Link className='linkadd' style={{fontSize:'20px',color:'black',marginLeft:'10px'}} to="/">Home</Link>
                       
                        <Link className='linkadd' style={{fontSize:'20px',color:'black',marginLeft:'10px'}} to="/about"><u>About</u></Link>
                        <Link className='linkadd' style={{fontSize:'20px',color:'black',marginLeft:'10px'}} to="/channelpartner">Partner with Us</Link>
                        {cookieNames[0] != undefined ? (
                      <Link className='linkadd' style={{ fontSize: '20px', color: 'black', marginLeft: '10px' }} onClick={handleLogout}>Logout</Link>
                    ) : (
                      <Link className='linkadd' style={{ fontSize: '20px', color: 'black', marginLeft: '10px' }} to="/login">Login</Link>
                    )} 
                      </Nav>
                      
                        
                      
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Container>
              </Navbar>
            ))}
          </>
        );
      }
    
  return (
    <div>
    <OffcanvasExample/>
    <div>
        {/* Display cookies */}
        <div>Welcome, <h7><b>{cookieNames.join(', ')}</b></h7> </div>
      </div>
    
    </div>
  )
}

export default Aboutnav