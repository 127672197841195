import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser'; // Import react-html-parser
import '../Styles/details.css';
import eyes from '../Images/eyes.png';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import Cookies from 'js-cookie';
const Traindetails = () => {
  const location = useLocation();
  const { train } = location.state;

  const tableRef = useRef(null); // Create a ref for the table element
  const nav = useNavigate();

  const [tableData, setTableData] = useState('');
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

  useEffect(() => {
    const apiUrl = `https://api.busparrot.com/getlivetrainstation.php?stname=${train.label}&stid=${train.value}`;

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const htmlTable = response.data.data; // Extract the HTML table from the response
        setTableData(htmlTable);
      } catch (error) {
        console.error('Error fetching train details:', error);
      }
    };

    fetchData();
  }, [train.value, train.id]);


  useEffect(() => {
    const fetchNearestStops = async (latitude, longitude) => {
      try {
        console.log(latitude, longitude, "latlon");
        const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
        console.log(apiUrl, 'stops');
        const response = await axios.get(apiUrl);
        console.log('API Response:', response);
        const nearestStopsData = response.data.stops.map((stop) => ({
          ID: stop.stop_id,
          stop_name: stop.stop_name,
          distance: stop.distance,
          lat: stop.stop_lat,
          lon: stop.stop_lon,
          lbcode: stop.stop_localbodycode,
          page:'train',
        }));
        setNearestStops(nearestStopsData);
        setDefaultSelectValue({
          value: nearestStopsData[0].ID,
          label: nearestStopsData[0].stop_name,
        });
      } catch (error) {
        console.error('Error fetching nearest stops data:', error);
      }
    };
  
    const storedLatitude = Cookies.get('latitude');
    const storedLongitude = Cookies.get('longitude');
  
    console.log('Stored Latitude:', storedLatitude);
    console.log('Stored Longitude:', storedLongitude);
  
    if (storedLatitude && storedLongitude) {
      fetchNearestStops(storedLatitude, storedLongitude);
    } else {
      console.error('Latitude or longitude cookies are not set');
    }
  }, []);

  return (
    <div className="bg-body-tertiary mb-3">
      <Details />
      <div className="t1">
        {ReactHtmlParser(tableData)}
      </div>
      <div>
  {nearestStops.length > 0 && (
    <div >
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>
    </div>
  );
};

export default Traindetails;
