import React, { useState, useEffect } from 'react';
import axios from 'axios';
import gmap from '../Images/gmap.png';
import phone  from '../Images/images1.jpg';
import whatsapp1 from '../Images/whatsapp.png';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png'; 
import Navpage4 from './Navpage4';
import '../Styles/navpage.css';
import Cookies from 'js-cookie'; 
import sm from '../Images/streetview.png';
import Footer from './Footer';

import facebook from '../Images/facebook.png';
import linkedin from '../Images/linkedin.png';
import twitter from '../Images/twitter.png';
import youtube from '../Images/youtube.png';
import Map from './Map';
const Services = () => {
  const [activeLink, setActiveLink] = useState(null);
  const [places, setPlaces] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [lastLocationLatitude1, setLastLocationLatitude1] = useState(null);
  const [lastLocationLongitude1, setLastLocationLongitude1] = useState(null);
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

  const [currentLocation, setCurrentLocation] = useState(null);
  const saveLocationInCookie = (latitude, longitude) => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    Cookies.set('latitude', latitude.toString(), { expires: expirationDate });
    Cookies.set('longitude', longitude.toString(), { expires: expirationDate });
  };
  const getUserLocation = async () => {
    // Check if geolocation is available
    if ('geolocation' in navigator) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            resolve({ latitude, longitude });
          },
          (error) => {
            console.error('Error getting the current location:', error);
            reject(error);
          },
          { enableHighAccuracy: true, maximumAge: 0 }
        );
      });
    } else {
      console.error('Geolocation is not available in your browser');
  
      // Fallback to cookie or IP location
      // Implement logic to get location from cookie or IP address here
  
      return null; // Return null if location cannot be determined
    }
  };
  const fetchIPBasedLocation = async () => {
    try {
      // Fetch user's location based on IP
      const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
      const ipLocation = ipApiResponse.data;

      // Use the IP location to construct the API URL
      const apiUrl = `https://yatraparrot.com/service/api/getservicesaround.php?center_lat=${ipLocation.latitude}&center_lon=${ipLocation.longitude}&radius=100`;

      // Fetch data from the API
      const response = await fetch(apiUrl);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setPlaces(data.result); // Set places data in state
      fetchNearestStops(ipLocation.latitude, ipLocation.longitude);
  
    } catch (error) {
      console.error('Error fetching data based on IP location:', error);
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the user's location
        const location = await getUserLocation();

        if (location) {
          // Use the user's location to construct the API URL
          const apiUrl = `https://yatraparrot.com/service/api/getservicesaround.php?center_lat=${location.latitude}&center_lon=${location.longitude}&radius=100`;

          // Save location in cookies
          saveLocationInCookie(location.latitude, location.longitude);

          // Fetch data from the API
          const response = await fetch(apiUrl);

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setPlaces(data.result); // Set places data in state
          fetchNearestStops(location.latitude, location.longitude);
   
        } else {
          // Handle the case where location cannot be determined
          console.log('Location not available');

          // Case: Location not available, get location from cookies
          const storedLatitude = Cookies.get('latitude');
          const storedLongitude = Cookies.get('longitude');

          if (storedLatitude && storedLongitude) {
            setLastLocationLatitude1(storedLatitude);
            setLastLocationLongitude1(storedLongitude);
            await saveLocationInCookie(storedLatitude, storedLongitude);
            fetchNearestStops(storedLatitude, storedLongitude);
   
          } else {
            // Handle the case where location cannot be determined
  
            // Case: Location not available, try IP location
            await fetchIPBasedLocation();
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          const accuracy = position.coords.accuracy;
          setCurrentLocation({ latitude, longitude, accuracy });
        },
        (error) => {
          console.error('Error getting the current location:', error);
        },
        { enableHighAccuracy: true, maximumAge: 0 }
      );
    } else {
      console.error('Geolocation is not available in your browser');
    }
  }, []);

  // console.log(currentLocation.latitude,"kk")

  const handleGetDirections = (place) => {
    // Assuming you have the latitude and longitude values for the source and destination
   
    
    const destinationLat =place.Latitude;
    const destinationLng = place.Longitude;
  
    // Create the Google Maps directions URL
    const directionsURL = `https://www.google.com/maps/dir/${currentLocation.latitude},${currentLocation.longitude}/${destinationLat},${destinationLng}`;

    // Open the URL in a new tab
    window.open(directionsURL);
  };

  const handleGetDirections1 = (place) => {
    // Assuming you have the latitude and longitude values for the source and destination
   
    
    const destinationLat =place.Latitude;
    const destinationLng = place.Longitude;
  
    // Create the Google Maps directions URL
    const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${destinationLat},${destinationLng}&heading=0&pitch=0`;

    // Open the URL in a new tab
     window.open(streetViewURL);
  };

  const cardStyle = {
    width: '100%', // Adjust the width as needed
    height: 'auto', // Set height to auto for responsiveness
    float: 'left', // Align to the left side
    margin: '10px 0', // Add margin for separation
    fontFamily: 'Comfortaa',
    borderRadius: '10px',
    color: 'black',
    backgroundColor: 'white',
  
    // Responsive styles
    '@media (max-width: 768px)': {
      width: '100%', // Set width to 100% for smaller screens
      float: 'none', // Remove float for stacked cards
      marginRight: 0, // Remove right margin for stacked cards
    },
  };
  const cardRight = {
    float: 'right',
    marginTop: '10px', // Add margin for separation
  
    // Responsive styles
    '@media (max-width: 768px)': {
      float: 'none', // Remove float for stacked cards
      marginTop: '10px', // Add margin for separation
    },
  };
  
  const carddetails = {
    margin: '10px', // Add margin for separation
  
    // Responsive styles
    '@media (max-width: 768px)': {
      margin: '10px', // Add margin for separation
    },
  };
  const navContainerStyle = {
    height: '100px',
    overflowX: 'auto',
  };

  const navLinkContainerStyle = {
    display: 'flex',
    backgroundColor: 'black',
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  // Define styles for each link
  const linkStyles = {
    Home: {
      textDecoration: 'none',
      margin: '10px 10px',
      cursor: 'pointer',
      color: activeLink === '/' ? 'yellow' : 'white',
    },
    Pooja: {
      textDecoration: 'none',
      margin: '10px 10px',
      cursor: 'pointer',
      color: activeLink === '/pooja' ? 'yellow' : 'white',
    },
    Parking : {
      textDecoration: 'none',
      margin: '10px 10px',
      cursor: 'pointer',
      color: activeLink === '/parking' ? 'yellow' : 'white',
    },
    Restrooms: {
      textDecoration: 'none',
      margin: '10px 10px',
      cursor: 'pointer',
      color: activeLink === '/restrooms' ? 'yellow' : 'white',
    },
    NearbyPlaces: {
      textDecoration: 'none',
      margin: '10px 10px',
      cursor: 'pointer',
      color: activeLink === '/nearbyplaces' ? 'yellow' : 'white',
    },
    HowToReach: {
      textDecoration: 'none',
      margin: '10px 10px',
      cursor: 'pointer',
      color: 'yellow',
    },
  };

  const imgStyle = {
    width: '200px',
    height: '80px',
  };

  const mediaQuery = window.matchMedia('(max-width: 768px)');

  if (mediaQuery.matches) {
    // Adjust font size for mobile view
    for (const styleKey in linkStyles) {
      linkStyles[styleKey].fontSize = '14px';
    }
    imgStyle.width = '100px'; // Adjust the image width for mobile view
    imgStyle.height = '40px'; // Adjust the image height for mobile view
  }

  const uniqueCategories = [...new Set(places.map((place) => place.Category))];

  const tabLinksContainerStyle = {
    overflowX: 'auto',
    minHeight: '40px',
    whiteSpace: 'nowrap',
    width: '100%', // Set a fixed width to enable scrolling
    
  };

  useEffect(() => {
    // Set the first Category to be opened when the page is refreshed
    if (places.length > 0) {
      setActiveTab(places[0].Category);
      console.log(activeTab)
    }
  }, [places]);
  

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  };

  const uniqueCategories1 = [...new Set(places.map((place) => place.Category))];

const categoriesWithCount = uniqueCategories1.map((Category) => {
  console.log(Category)
  return {
    name: Category,
    
    count: places.filter((place) => place.Category.toLowerCase() === Category).length,
  };
 
});

const mergedCategories = categoriesWithCount.reduce((acc, Category) => {
  const existingCategory = acc.find((c) => c.name === Category.name);
  if (existingCategory) {
    existingCategory.count += Category.count;
  } else {
    acc.push(Category);
  }
  return acc;
}, []);
const containsMixedCase = (str) => {
  return /[a-z]/.test(str) && /[A-Z]/.test(str);
};
const isSpellingMatch = (str1, str2) => {
  if (str1 && str2) {
    return str1 === str2;
  }
  return false;
};
const handlemessage = async (trip) => {
  const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
  const userIpAddress = ipApiResponse.data.ip;
  const currentTime = new Date(); 
  const feedbackData = {
    appname: 'Services',
    category: 'Place',
    infoid: "placeid : "+trip.id,
    customerid: 'Not defined',
    ipaddress: userIpAddress,
    feedback: 'Correct',
    message: 'Not defined',
    time: currentTime.toLocaleString(),
  }

  const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
  const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;
  

  try {
    const response = await fetch( urlWithParams, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
      body: JSON.stringify({
        // Add your data here
        
        // Add other data fields as needed
      }),
    });

    console.log(response, "feedback response");


    if (response.status === 200) {
      // Display an alert when feedback is successfully posted
      window.alert('Feedback posted successfully!');
    } else {
      // Display an alert for any other response status
      window.alert('Failed to post feedback. Please try again.');
    }
    // Handle the response accordingly
  } catch (error) {
    console.error('Error sending feedback:', error);
  }
};
const smallButtonStyle = {
  width: '40px',
};



const handlemessage1 = async (trip) => {
  const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
  const userIpAddress = ipApiResponse.data.ip;
  const currentTime = new Date();

  // Show a prompt to enter the message
  const userMessage = window.prompt('Enter your feedback message:');

  // Check if the user clicked cancel
  if (userMessage === null) {
    window.alert('Feedback submission canceled.');
    return;
  }

  const feedbackData = {
    appname: 'Services',
    category: 'Place',
    infoid: "placeid"+trip.id,
    customerid: 'Not defined',
    ipaddress: userIpAddress,
    feedback: 'Correct',
    message: userMessage || 'No Messages', // Use user input or 'No Messages' if empty
    time: currentTime.toLocaleString(),
  };

  const apiUrl = 'https://yatraparrot.com/feedback/customer_feedback.php';
  const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;

  try {
    const response = await fetch(urlWithParams, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
      body: JSON.stringify({
        // Add your data here
        // Add other data fields as needed
      }),
    });

    console.log(response, 'feedback response');

    if (response.status === 200) {
      // Display an alert when feedback is successfully posted
      window.alert('Feedback posted successfully!');
    } else {
      // Display an alert for any other response status
      window.alert('Failed to post feedback. Please try again.');
    }
    // Handle the response accordingly
  } catch (error) {
    console.error('Error sending feedback:', error);
  }
};

const smallButtonStyle1 = {
  width: '30px', // Adjust the width value as needed
   
    
    // backgroundColor: ' #f72e42',
    marginLeft: '10px',
    marginTop:'8px'
};

const fetchNearestStops = async (latitude, longitude) => {
  try {
    console.log(latitude,longitude,"latlon");
    const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
    console.log(apiUrl,'stops')
    const response = await axios.get(apiUrl);

    console.log('API Response:', response);

    const nearestStopsData = response.data.stops.map((stop) => ({
      ID: stop.stop_id,
      stop_name: stop.stop_name,
      distance: stop.distance,
      lat: stop.stop_lat,
      lon: stop.stop_lon,
      lbcode:stop.stop_localbodycode,
      page:'service',
    }));

    setNearestStops(nearestStopsData);
    setDefaultSelectValue({
      value: nearestStopsData[0].ID,
      label: nearestStopsData[0].stop_name,
    });
  } catch (error) {
    console.error('Error fetching nearest stops data:', error);
  }
};





  return (
    <div>
      <Navpage4 />
<br/>

      <div className="tab-container">
  <div className="tab-links" style={tabLinksContainerStyle}>
  {mergedCategories.map((Category) => (
    <div
      key={Category.name}
      className={`tab-link ${activeTab === Category.name ? 'active' : ''}`}
      onClick={() => setActiveTab(Category.name)} style={{
        overflowX: 'auto',
        marginTop: '50px',
        marginLeft: '3px',
        whiteSpace: 'nowrap',
        display: 'inline',
        padding: '5px',
        backgroundColor: '#76c045',
        color: 'white',
        border: '1px solid white',
        borderRadius: '15px',
      }}
    >
      {Category.name.toUpperCase()} 
    </div>
    ))}
  </div>
</div>
     

        <div className="tab-content">
        {uniqueCategories.length === 0 ? (
    <p style={{ color: 'red', fontSize: '20px', marginLeft: '30px' }}>  The public transport system in your area is not digitized yet. Please contact busparrot through your local body to digitize.</p>
    
  ) : (
          uniqueCategories.map((Category) => (
            <div
              key={Category}
              className={`tab-pane ${activeTab === Category ? 'active' : ''}`}
            >
              <div className="button-container">
                <div className="card-container">
                  {places
                    .filter((place) => isSpellingMatch(place.Category, activeTab))
               
    .map((place) => (
      <div className="card container-fluid" style={cardStyle} key={place.id}>
        <div className="place-card container-fluid" style={carddetails} key={place.id}>
        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
         
         <div onClick={() => handlemessage(place)}>
         <h7>Was this Correct?</h7>
           <img src='https://busparrot.com/assets/like.png' alt="Correct" style={smallButtonStyle} />
         </div>
         <div onClick={() => handlemessage1(place)}>
           <img src='https://busparrot.com/assets/dislike.png' alt="InCorrect" style={smallButtonStyle1} />
         </div>
       </div>
          <h3>{place.Category}</h3>
         
          {currentLocation && (
                 
                    
                 <span style={cardRight}>
                   {calculateDistance(
                     currentLocation.latitude,
                     currentLocation.longitude,
                     place.Latitude,
                     place.Longitude

                   ).toFixed(2)} kmaway 
                   <br/><a  onClick={() => handleGetDirections(place)}>
                   <img
                     src={gmap}
                     alt="Google Maps Icon"
                     style={{ width: '20px', height: '20px', marginRight: '10px' }}
                   />
                 </a><span className='go'>Go </span>
                 <a  onClick={() => handleGetDirections1(place)}>
                   <img
                     src={sm}
                     alt="Google Maps Icon"
                     style={{ width: '35px', height: '30px', marginRight: '10px',marginLeft:'10px' }}
                         />
                 </a>
                 </span>
              
             )}
          <p>
           Name : {place.Name}{' '}
           {place.MobileNumber && (
    <span>
      <a href={`tel:${place.MobileNumber}`}>
        <img
          src={phone} // Replace with the actual path to your phone icon image
          alt="Phone Icon"
          style={{ width: '30px', height: '30px', marginRight: '10px' ,marginLeft:'10px'}}
        />
      </a>
      <a href={`https://wa.me/${place.MobileNumber}`}>
        <img
          src={whatsapp1}
          alt="whatsapp"
          style={{ width: '30px', height: '30px',marginLeft:'10px' }}
        />
      </a>
    </span>
  )}
            {/* <span style={cardRight}>
              {parseFloat(place.distance).toFixed(2)} km away
            </span> */}
            

          </p>
          
          <p>
  City: {place.City}{' '}
 
</p>

                  <p>
                 
                    
                  </p>
                  {/* <button style={smallButtonStyle} onClick={() => handlemessage(place)}>Correct</button>
        <button style={smallButtonStyle1} onClick={() => handlemessage1(place)}>InCorrect</button> */}


<br/>

        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
         ))
         )}
        </div>
        <div className="mapstyle">
        <Map />
      </div>
      <br />
      <div style={{ textAlign: 'center', marginBottom: '750px' }}>
        <a href="https://m.facebook.com/100063656121677/">
          <img className="imagesocial" style={{ width: '30px' }} src={facebook} alt="Facebook" />
        </a>
        <a href="https://twitter.com/BusParrot?s=08">
          <img className="imagesocial" style={{ width: '30px' }} src={twitter} alt="Twitter" />
        </a>
        <a href="https://youtube.com/@user-mk1uk6sq2t">
          <img className="imagesocial" style={{ width: '30px' }} src={youtube} alt="YouTube" />
        </a>
        <a href="https://www.linkedin.com/company/busparrot/">
          <img className="imagesocial" style={{ width: '30px' }} src={linkedin} alt="LinkedIn" />
        </a>
      </div> 

        
<div>
  {nearestStops.length > 0 && (
    <div>
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>
      </div>
  )

};

export default Services;