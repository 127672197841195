import React, { useState } from 'react';
import axios from 'axios';
import '../Styles/login.css'; // Import your CSS file for styling
import Navbar from 'react-bootstrap/Navbar';
import busparroticon from '../Images/busparrotlogo.png';
import { Link } from 'react-router-dom';
import Loginnav from './Loginnav';
import facebook from '../Images/facebook.png'
import linkedin from '../Images/linkedin.png'
import twitter from '../Images/twitter.png'
import youtube from '../Images/youtube.png'
const Signup = () => {
  const [data, setData] = useState(null);
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const handleSignup = async() => {
    // Create an object with the user input data
   

    // Make the Axios POST request with user data
    await axios
      .post(`https://api.yatraparrot.com/passenger/quickregister_get.php?mobile=${phone}&pw=${password}&pname=${fullName}`)
      .then((response) => {
        // Handle the response data here
        console.log(response.data);
        setData(response.data);
        alert(response.data.message)
      })
      .catch((error) => {
        // Handle any errors here
        console.error('Error:', error);
      });
  };

  return (
    <div>
    <Loginnav/>
      

      <div className="login-container">
        <div className="login-box">
          <h2>Register</h2><br/>
          <div>
          <input
            className='i2'
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          /></div>
{/*          
          <div className="phone-input"> */}
            {/* <input
              className='i1'
              type="text"
              value='91'
              placeholder="Country"
              readOnly
            />
            <hr className="divider" /> */}
            <div><br/>
            <input
              className='i2'
              type="number"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
</div>
            <br/>
          {/* </div> */}

          <div>
          <input
            className='i2'
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
            </div>
          <br/>
          <button className='b1' onClick={handleSignup}>Signup</button><br/>
          <h7 className='l'><Link to ='/login' className='l'>Already have an Account? Login</Link></h7>
        </div>
      </div>

      {/* Display the data from the API if available */}
      {data && (
        <div>
          <h3>API Response:</h3>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}

      <div style={{textAlign:'center',marginBottom:'750px'}}>
    <a href='https://m.facebook.com/100063656121677/'><img className="imagesocial" style={{width:'30px'}} src={facebook} alt=""></img></a>
<a href='https://twitter.com/BusParrot?s=08'><img className="imagesocial" style={{width:'30px'}} src={twitter} alt=""></img></a>
<a href='https://youtube.com/@user-mk1uk6sq2t'><img className="imagesocial" style={{width:'30px'}} src={youtube} alt=""></img></a>
<a href='https://www.linkedin.com/company/busparrot/'><img className="imagesocial" style={{width:'30px'}} src={linkedin} alt=""></img></a>
</div>
    </div>
  );
}

export default Signup;
