import React, { useState, useEffect } from 'react'

const Cookie = () => {
    const [cookieNames, setCookieNames] = useState([]);
    const getCookies = () => {
        const cookieString = document.cookie;
        const cookiesArray = cookieString.split(';');
        const cookieNames = cookiesArray.map((cookie) => {
         
          const [, cookieValue] = cookie.split('=').map((item) => decodeURIComponent(item.trim()));
          return cookieValue;
        });
        console.log(cookieNames)
        setCookieNames(cookieNames);
      };
  return (
    <div>



    </div>
  )
}

export default Cookie