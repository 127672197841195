import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/partner.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'; // Import NavDropdown from React Bootstrap
import Offcanvas from 'react-bootstrap/Offcanvas';
import services from '../Images/services.jpg';
import busparroticon from '../Images/busparrotlogo.png';
import Partnerinvitenav from './Partnerinvitenav';
import axios from 'axios'; 

const Partnerinvite = () => {
  const divStyle = {
    textAlign: 'left',
    fontFamily: 'Comfortaa, sans-serif', // Specify Comfortaa as the font
  };
  


  const divStyle2 = {
    border: '2px solid transparent', // Transparent border
    borderRadius: '4px', // Rounded corners for a presentable look
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Box shadow effect
    padding: '20px', // Optional padding for content
  };
  const divStyle3 = {
    border: '2px solid transparent', // Transparent border
    borderRadius: '4px', // Rounded corners for a presentable look
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Box shadow effect
    padding: '20px', // Optional padding for content
    backgroundColor:'#76c045',
    color:'white'
  };
   
  const divStyle4 = {
    border: '2px solid transparent', // Transparent border
    borderRadius: '4px', // Rounded corners for a presentable look
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)', // Box shadow effect
    padding: '20px',
    // Optional padding for content
  };

  function OffcanvasExample() {
    return (
      <>
        {['lg'].map((expand) => (
          <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
            <Container fluid>
              <Navbar.Brand  href="/"><h1 className='header container-fluid'>
    <img className='image2' src={busparroticon} alt="BusParrot" />
    busparrot</h1></Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                style={{backgroundColor:'#f72e42'}}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    
                  </Offcanvas.Title>
                </Offcanvas.Header>
               
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
    );
  }


  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
  });

  const [formResponse, setFormResponse] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://yatraparrot.com/channelpartner/apply/contact_form.php', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = response.data;
      console.log(data)

      setFormResponse(data.success ? 'Message sent successfully!' : 'Message failed to send. Please try again later.');
    } catch (error) {
      console.error('Error:', error);
      setFormResponse('An error occurred while sending the message.');
    }
  };
  return (
    <div>
    <Partnerinvitenav/>
    <div style={divStyle} className='bg-body-tertiary mb-3'>
    
    <h1 className='h11'>Busparrot Partner Program</h1><br/>
      <h1 className='h12'>Join Us in Transforming Public Transport!</h1>
      <p>Are you ready to be part of the future of public transport? BusParrot is looking for innovative partners to join our mission of making public transportation smarter, more efficient, and more profitable. As an AI passenger information and adtech business, 
      we're at the forefront of revolutionizing the industry.</p>
      <h1 className='h12'>About BusParrot
</h1>
<p>BusParrot is a leading player in the public transport technology landscape.
 We provide cutting-edge AI solutions to enhance passenger experiences, streamline operations,
  and generate new revenue streams for transit agencies and advertisers.

</p>
<div style={divStyle2}>
<h1 className='h12'>Our offerings include</h1><br/>
<h3> <u><b>Passenger Information Systems (PIS):</b></u> </h3><br/>
<h4 className='h41'> Real-time updates, route information, and travel advisories for passengers.</h4><br/>
<h3> <u><b>Advertising Solutions:</b></u> </h3><br/>
<h4 className='h41'> Targeted and dynamic ad placements that boost revenue for transit agencies and advertisers.</h4><br/>
<h3> <u><b>Data Analytics:</b></u> </h3><br/>
<h4 className='h41'>In-depth insights and data-driven decision support for public transport stakeholders.</h4><br/>
<h3> <u><b>Smart Mobility Solutions:</b></u> </h3><br/>
<h4 className='h41'>Integrations for ticketing, journey planning, and more.


.</h4><br/>

</div><br/>
<h1 className='h12'>Why Partner with BusParrot?</h1><br/>
<h4><span className='sp1'>InnovativeTechnology</span></h4><br/>
<p>Access our advanced AI and adtech solutions, backed by cutting-edge technology.
</p><br/>

<h4><span className='sp1'>MarketLeadership</span></h4><br/>
<p> Join forces with a recognized industry leader and access a vast customer base.

</p><br/>
<h4><span className='sp1'>RevenueOpportunities</span></h4><br/>
<p>Grow your business by offering our solutions to your clients.
</p><br/>
<h4><span className='sp1'>DedicatedSupport</span></h4><br/>
<p> Receive training, marketing resources, and ongoing support from our team.



</p><br/>



<div style={divStyle2}>
<h1 className='h12'>PartnerBenefits</h1><br/>
<h3> <u><b>Generous Commissions:</b></u> </h3><br/>
<h5 className='h41'> Earn competitive commissions on sales and referrals.
</h5><br/>
<h3> <u><b>Sales and Marketing Support:</b></u> </h3><br/>
<h5 className='h41'>  Access marketing collateral, sales training, and co-marketing opportunities.
.</h5><br/>
<h3> <u><b>ProductTraining:</b></u> </h3><br/>
<h5 className='h41'>Get up to speed quickly with comprehensive product training.
</h5><br/>
<h3> <u><b>Technical Support:</b></u> </h3><br/>
<h5 className='h41'>Receive assistance with implementation and technical issues.


.</h5><br/>
<h3> <u><b>Exclusive Partner Portal:</b></u> </h3><br/>
<h5 className='h41'>Access resources, track progress, and manage leads through our dedicated portal.



</h5><br/>

</div><br/>



<div style={divStyle3}>
    <h1 >How to Get Started</h1><br/>
    <h7 style={{color:'black'}}>Getting started as a BusParrot channel partner is easy</h7><br/>
    <h7 >1. Complete the Partner Application: Fill out our partner application form to express your interest.
</h7><br/>
<h7>2. Review and Agreement: Our team will review your application and get in touch to discuss terms and agreements.</h7><br/>
<h7>3. Onboarding and Training: Once approved, we'll provide you with access to our resources and training materials.</h7><br/>
<h7>4. Start Selling: Begin offering BusParrot's solutions to your clients and start earning commissions.</h7><br/>
</div><br/>
<p>Join us in shaping the future of public transportation. Together, we can make traveling smarter, more efficient, and more enjoyable for millions of passengers worldwide.
</p>





<div style={divStyle4} className="contact-form">
<div className="form-heading">Become a Partner</div>
        <div className="form-subheading">
          <u>Channel Partner Form</u>
        </div>

        {formResponse && <p>{formResponse}</p>}

        <form onSubmit={handleSubmit}>
          <label className="form-label" htmlFor="name">
            Name:
          </label>
          <input
            className="form-input"
            type="text"
            id="name"
            name="name"
            required
            value={formData.name}
            onChange={handleInputChange}
          />
          <br />

          <label className="form-label" htmlFor="phone">
            Phone:
          </label>
          <input
            className="form-input"
            type="tel"
            id="phone"
            name="phone"
            required
            value={formData.phone}
            onChange={handleInputChange}
          />
          <br />

          <label className="form-label" htmlFor="email">
            Email:
          </label>
          <input
            className="form-input"
            type="email"
            id="email"
            name="email"
            required
            value={formData.email}
            onChange={handleInputChange}
          />
          <br />
          <br />
          <button type="submit">Submit</button>
        </form>
      </div>
  </div>
  </div>
  );
};

export default Partnerinvite;
