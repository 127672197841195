import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import busparroticon from '../Images/busparrotlogo.png';
import banner from '../Images/banner.png'
import image3 from '../Images/Image3.png'
import Mission from '../Images/Mission.jpg'
import Leader from '../Images/Leader.jpg'
import image9 from '../Images/Image9.png'
import facebook from '../Images/facebook.png'
import linkedin from '../Images/linkedin.png'
import twitter from '../Images/twitter.png'
import youtube from '../Images/youtube.png'
import bus2 from '../Images/bus2.png'
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';

import '../Styles/about.css'
import Aboutnav from './Aboutnav';

const About = () => {

  
  function OffcanvasExample() {
    return (
      <>
        {['lg'].map((expand) => (
          <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
            <Container fluid>
              <Navbar.Brand href="/Matrimonynew/"><h1 className='header container-fluid'>
    <img className='image2' src={busparroticon} alt="BusParrot" />
    busparrot</h1></Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                style={{backgroundColor:'#f72e42'}}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    
                  </Offcanvas.Title>
                </Offcanvas.Header>
               
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </>
    );
  }



  function GroupExample() {
    const cardStyle = {
      margin: '0 10px',
      outerHeight:'300px' // Adjust the margin as needed
    };
    const cardTextStyle = {
      fontSize: '14px', // Adjust the font size as needed
    };
    const buttonStyle = {
      backgroundColor: ' #76c045', // Change the button color
      color: 'white', // Change the text color
      width: '150px', // Adjust the width as needed
      height: '40px', // Adjust the height as needed
    };
    return (
      <CardGroup>
        <Card style={cardStyle}>
          <Card.Img variant="top" src={image3} />
          <Card.Body>
            <Card.Title><b>
Busparrot App</b></Card.Title>
            <Card.Text style={cardTextStyle}>
            Multimodal trip planning app
for passengers
helping them to spot their public transport
            </Card.Text>
          </Card.Body>
          <Card.Footer>
          <Button  style={buttonStyle} variant="primary">Go somewhere</Button>
          </Card.Footer>
        </Card>
        <Card style={cardStyle}>
          <Card.Img variant="top" src={image3} />
          <Card.Body>
            <Card.Title><b>
Busparrot App</b></Card.Title>
            <Card.Text style={cardTextStyle}>
            App for bus operators
helping them publishing their schedules, On board Next stop announcement
departure announcement, passenger canvassing etc
            </Card.Text>
          </Card.Body>
          <Card.Footer>
          <Button style={buttonStyle} variant="primary">Go somewhere</Button>
          </Card.Footer>
        </Card>
        <Card style={cardStyle}>
          <Card.Img variant="top" src={image3} />
          <Card.Body>
            <Card.Title><b>
Busparrot App</b></Card.Title>
            <Card.Text style={cardTextStyle}>
            Helping you to digital transform public transport system of your city
by Route Marking
and Publishing
            </Card.Text>
          </Card.Body>
          <Card.Footer>
          <Button style={buttonStyle} variant="primary">Go somewhere</Button>
          </Card.Footer>
        </Card>
      </CardGroup>
    );
  }



  function GridExample() {

    const cardStyle = {
      margin: '30px 80px',
      innerHeight: '500px',
      outerHeight: '500px', // Adjust the margin as needed
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center', // Adjust the margin as needed
    };
    const cardTextStyle = {
      fontSize: '14px', // Adjust the font size as needed
    };
    const imageStyle = {
      width: '200px', // Adjust the width as needed
      height: '200px',
       // Adjust the height as needed
    };
    return (
      <CardGroup>
      <Card style={cardStyle}>
        <Card.Img style={imageStyle}  variant="top" src={Leader} />
        <Card.Body>
          <Card.Title><b>
          Our Mission</b></Card.Title>
          <Card.Text style={cardTextStyle}>
          “Organize the world's transit information and
make it accessible and useful to the passengers”
          </Card.Text>
        </Card.Body>
        <Card.Footer>
     
        </Card.Footer>
      </Card>
      <Card style={cardStyle}>
        <Card.Img style={imageStyle}  variant="top" src={Mission} />
        <Card.Body>
          <Card.Title><b>
          Our Vision</b></Card.Title>
          <Card.Text style={cardTextStyle}>
          “To be a pioneer in the passenger information
industry in the world”
          </Card.Text>
        </Card.Body>
        <Card.Footer>
      
        </Card.Footer>
      </Card>
     
      
    </CardGroup>
    );
  }



  function GridExample2() {

    const cardStyle = {
      margin: '30px 80px',
      innerHeight: '500px',
      outerHeight: '500px', // Adjust the margin as needed
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center', // Adjust the margin as needed
    };
    const cardTextStyle = {
      fontSize: '14px', // Adjust the font size as needed
      textAlign:'start'
    };
   const cardTitle={
    color:'#f72e42',
    
   }
    return (
      <CardGroup>
      <Card style={cardStyle}>
       
        <Card.Body>
          <Card.Title style={cardTitle}><b>
          Technology</b></Card.Title>
          <Card.Text style={cardTextStyle}>
          <br/>
          GTFS based Passenger Information Platform<br/><br/>
AI, ICT, IoT and Cloud based technologies<br/><br/>
Intuitive GI & Design<br/><br/>
No Boss No Office System<br/><br/>
Easy to Deploy & Monitor
          </Card.Text>
        </Card.Body>
        <Card.Footer>
     
        </Card.Footer>
      </Card>
      <Card style={cardStyle}>
      
        <Card.Body>
          <Card.Title style={cardTitle}><b>
          FREE for Passengers</b></Card.Title>
          <Card.Text style={cardTextStyle}>
          <br/>
          Ad Tech driven Revenue Model <br/> <br/>
Attractive & Scaleable Channel Partner Program<br/> <br/>
High reach & engagement for advertisers & sponsors<br/> <br/>
          </Card.Text>
        </Card.Body>
        <Card.Footer>
      
        </Card.Footer>
      </Card>
      <Card style={cardStyle}>
        
        <Card.Body>
          <Card.Title style={cardTitle}><b>
          Smart & Green City Compliant</b></Card.Title>
          <Card.Text style={cardTextStyle}><br/>
          High reach & engagement for advertisers & sponsors<br/> <br/>
Drive Public Transport usage thereby reduce traffic & accidents<br/> <br/>
Reduce pollution and accidents<br/> <br/>
Improve ease of mobility & lower cost of living<br/> <br/>
          </Card.Text>
        </Card.Body>
        <Card.Footer>
      
        </Card.Footer>
      </Card>
     
      
    </CardGroup>
    );
  }

  return (
    <div>
    <Aboutnav/>
    <div className='about'>
    
    <div className='content-container'>
    <br/>
 
        <h1 ><b>WE HELP PLAN BETTER

CITIES</b></h1>
          <p className='p1'>Public Transport Digitalization Consulting and Solutions. The public transport system is incomplete without adequate passenger information  infrastructure.<br/> We innovate and manufacture passenger information solutions for the public transport industry
          <br/><button className='b13'>Explore</button>
</p>
        <img className="img1"  src={banner} alt="" /><br/>
<p className='p1'><h1 className='sp12'>“A developed country is not a place where the poor have cars. It's where the rich use public transportation”

</h1>Gustavo Petro, Mayor of Bogota

</p><br/>
<h1 ><b>DIGITALIZING PUBLIC TRANSPORT</b></h1>
<GroupExample/>
    </div>
   <div >
   <GridExample/>
   </div>
   
     <div>
     <h1 ><b>ABOUT US</b></h1>
     <p className='p1'>Bus Parrot is a technology solutions developer for the Public Transport sector and has solutions that seamlessly connect all the stakeholders in delivery of services ensuring Public Transport becomes the first choice of all travelers.
Buses provide both long distance and last mile connectivity in urban and rural areas. Bus Parrot covers the information needs of the bus passengers through ‘Bus Parrot App’ – A Bus Travellers Pet connected to and updated by service providers built on state of the art and scalable ICT, IOTS & AI technology platforms.</p>
<img className="img1"  src={image9} alt="" /><br/>
<div className="align">
<div  >
                                          <img  src="https://yatraparrot.com/assets/bus.png" alt=""/>
                                          <p className='p1'>Trip</p>
                                        </div>
                                        <div  >
                                          <img  src="https://yatraparrot.com/assets/route.png" alt=""/>
                                          <p className='p1'>Route</p>
                                        </div>
                                      
                                       
                                        <div >
                                          <img src="https://yatraparrot.com/assets/bus-stop.png" alt=""/>
                                          <p className='p1' >Stop</p>
                                        </div>
                                        <div  >
                                          <img  src="https://yatraparrot.com/assets/tour-bus.png" alt=""/>
                                          <p className='p1'>Bus</p>
                                        </div>
                                      </div>
                                      </div><br/>
      <div>                              
        <h1 ><b>
WHY BUS PARROT !</b></h1>

<img className="img1"  src={bus2} alt="" /><br/>  
<br/>
<p className='p1'> We innovate and manufacture ICT, IoT passenger information solutions for the public transport industry, helping passengers to move by making real time bus information available at bus stations, bus stops, buses and passenger mobile apps.</p>                             
<br/>

<p className='p1'>We aim to promote public transportation through its digital transformation. Improved ICT passenger information infrastructure can attract more passengers to public transportation, encourage the use of it and eliminate a lot of private vehicles from the roads.</p>
</div>  

<div>



</div>
<GridExample2/>

<div className='contact-us'>
        <h1><b>Contact Us</b></h1>
        <p>Busparrot, Software Technology Park, New Industrial Development Area, Kanjikode, Palakkad 678621</p>
        <p>Call: 9074507122</p>
        <p>Email: mail@busparrot.com</p>
      </div>
<a href='https://m.facebook.com/100063656121677/'><img className="imagesocial" style={{width:'30px'}} src={facebook} alt=""></img></a>
<a href='https://twitter.com/BusParrot?s=08'><img className="imagesocial" style={{width:'30px'}} src={twitter} alt=""></img></a>
<a href='https://youtube.com/@user-mk1uk6sq2t'><img className="imagesocial" style={{width:'30px'}} src={youtube} alt=""></img></a>
<a href='https://www.linkedin.com/company/busparrot/'><img className="imagesocial" style={{width:'30px'}} src={linkedin} alt=""></img></a>

    </div>
    </div>
  )
}

export default About