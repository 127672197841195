import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import '../Styles/details.css';
import eyes from '../Images/eyes.png';
import redparrot from '../Images/redParrot.ico';
import laststep from '../Images/laststep.png';
import Footer from './Footer';
import Cookies from 'js-cookie';
const Tripbusdetails = () => {
  const location = useLocation();
  const { route, bus ,id} = location.state;
  const [tripDetails, setTripDetails] = useState(null);
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

  const divStyle = {
    textAlign: 'center',
    fontFamily: 'Comfortaa, sans-serif', // Specify Comfortaa as the font
  };
  console.log({id},"lll")

  useEffect(() => {
    // Define the URL with from.value and to.value
    const apiUrl = `https://api.busparrot.com/gettripdetails.php?tripid=${id}`;

    const fetchData = async () => {
      try {
        // Fetch trip details using Axios and await the response
        const response = await axios.get(apiUrl);
        // Set the trip details in the state
        setTripDetails(response.data);
        console.log(response)
      } catch (error) {
        console.error('Error fetching trip details:', error);
      }
    };

    fetchData();
  }, [id]);



  useEffect(() => {
    const fetchNearestStops = async (latitude, longitude) => {
      try {
        console.log(latitude, longitude, "latlon");
        const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
        console.log(apiUrl, 'stops');
        const response = await axios.get(apiUrl);
        console.log('API Response:', response);
        const nearestStopsData = response.data.stops.map((stop) => ({
          ID: stop.stop_id,
          stop_name: stop.stop_name,
          distance: stop.distance,
          lat: stop.stop_lat,
          lon: stop.stop_lon,
          lbcode: stop.stop_localbodycode,
          page:'trip',
        }));
        setNearestStops(nearestStopsData);
        setDefaultSelectValue({
          value: nearestStopsData[0].ID,
          label: nearestStopsData[0].stop_name,
        });
      } catch (error) {
        console.error('Error fetching nearest stops data:', error);
      }
    };
  
    const storedLatitude = Cookies.get('latitude');
    const storedLongitude = Cookies.get('longitude');
  
    console.log('Stored Latitude:', storedLatitude);
    console.log('Stored Longitude:', storedLongitude);
  
    if (storedLatitude && storedLongitude) {
      fetchNearestStops(storedLatitude, storedLongitude);
    } else {
      console.error('Latitude or longitude cookies are not set');
    }
  }, []);

  return (
    <div className='bg-body-tertiary mb-3'>
      <Details />
      <div style={divStyle} className="bg1">
        <h6 className='hstyle'>
          <b>{route}</b>
        </h6>
        <h6 className='hstyle1'><b>to</b></h6>
        <h6 className='hstyle1'>
          <b>{bus}</b>
        </h6>
      </div>
      <div className='sa'>
  {tripDetails && tripDetails.success === 1 && (
    <div className="trip-list">
      {tripDetails.result.map((trip, index) => (
        <div className='det' key={trip.Trip_id}>
        <img className='image1' src={index === tripDetails.result.length - 1 ? laststep : redparrot} alt="BusParrot" />
      
          <div className="trip-details">
            <p> <b>{trip.arrival_time} Arrival</b></p>
            <h6><b> {trip.stop_name}</b></h6>
           
            <p><b>{trip.departure_time} Departure</b></p>
          </div>
        </div>
      ))}
    </div>
  )}
</div>

<div>
  {nearestStops.length > 0 && (
    <div >
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>
    </div>
  );
  
 
};

export default Tripbusdetails;
