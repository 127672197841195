import React, { useState, useEffect, useRef } from 'react';


import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import StartIcon from '../Images/start.png';
import StopIcon from '../Images/stop.png';
import redparrot from '../Images/garbagetruck.png';
import gb from '../Images/grb1.png';
import { Navbar, Nav } from 'react-bootstrap';


const CommonTable = ({ columns }) => {
  return (
    <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px', border: '1px solid #ddd' }}>
    <thead>
      <tr style={{ border: '1px solid #ddd', background: '#f2f2f2' }}>
        {columns.map((column, index) => (
          <th key={index} style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>{column}</th>
        ))}
      </tr>
    </thead>
  <tbody>
      
      <tr style={{ border: '1px solid #ddd' }}>
          <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}> </td>
         </tr>
     </tbody> 
    </table>
  );
};


const Wastecollectionhome = ({ currentLatitude, currentLongitude }) => {
  const [position, setPosition] = useState([10.56376713, 76.59628137]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const mapRef = useRef(null);
  const taxisLayer = useRef(L.layerGroup());
  const routingControlRef = useRef(null);
  const startMarkerRef = useRef(null);
  const stopMarkerRef = useRef(null);
  const predefinedLatitude = 10.56376713; // Replace with your desired latitude
  const predefinedLongitude = 76.59628137;
  const [activeTab, setActiveTab] = useState('route');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // ... (previous code)
// ... (previous code)


// ... (rest of the code)


// ... (rest of the code)






useEffect(() => {
  const fetchData = async () => {
    try {
      // Fetch wastebin data
      const wastebinsResponse = await fetch(
        `https://busparrot.com/pis/api/getwastebinsaround.php?center_lat=${predefinedLatitude}&center_lon=${predefinedLongitude}&radius=10`
      );

      const wastebinsData = await wastebinsResponse.json();

      if (wastebinsData.success === 1) {
        const wastebins = wastebinsData.stops;

        if (mapRef.current) {
          const wastebinsLayer = L.layerGroup();

          wastebins.forEach((wastebin) => {
            const wastebinMarker = L.marker([wastebin.stop_lat, wastebin.stop_lon], {
              icon: L.icon({
                iconUrl: redparrot,
                iconSize: [40, 40],
              }),
            }).bindPopup(wastebin.stop_name);

            wastebinsLayer.addLayer(wastebinMarker);
          });

          wastebinsLayer.addTo(mapRef.current);
        }
      } else {
        console.error('Failed to fetch wastebin data');
      }
    } catch (error) {
      console.error('Error fetching wastebin data:', error);
    }
  };

  fetchData();
}, [predefinedLatitude, predefinedLongitude]);


useEffect(() => {
  const fetchTaxiData = async () => {
    try {
      // Fetch taxi data
      const taxisResponse = await fetch(
        `https://busparrot.com/pis/api/v1/gettaxisaround.php?center_lat=${predefinedLatitude}&center_lng=${predefinedLongitude}&radius=10`
      );

      const taxisData = await taxisResponse.json();

      console.log('Taxi Data Response:', taxisData.taxis);

      if (taxisData.success === 1) {
        const taxis = taxisData.taxis;

        if (mapRef.current) {
          taxisLayer.current.clearLayers();

          taxis.forEach((taxi) => {
            const taxiMarker = L.marker([taxi.taxi_lastlat, taxi.taxi_lastlng], {
              icon: L.icon({
                iconUrl: gb,
                iconSize: [80, 80],
              }),
            }).bindPopup(taxi.taxi_type);

            taxisLayer.current.addLayer(taxiMarker);
          });

          taxisLayer.current.addTo(mapRef.current);
        }
      } else {
        console.error('Failed to fetch taxi data');
      }
    } catch (error) {
      console.error('Error fetching taxi data:', error);
    }
  };

  // Fetch taxi data initially
  fetchTaxiData();

  // Update taxi data every 10 seconds
  const intervalId = setInterval(fetchTaxiData, 10000);

  // Clear interval on component unmount
  return () => clearInterval(intervalId);
}, [predefinedLatitude, predefinedLongitude]);


  useEffect(() => {
    if (position[0] && position[1]) {
      if (!mapRef.current) {
        const map = L.map('map').setView(position, 13);
        mapRef.current = map;

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);

        const startIcon = L.icon({
          iconUrl: StartIcon,
          iconSize: [64, 64],
        });

        const stopIcon = L.icon({
          iconUrl: StopIcon,
          iconSize: [64, 64],
        });

        // Add start marker
        const startMarker = L.marker([position[0], position[1]], {
          icon: startIcon,
          draggable: true,
        }).addTo(map);

        startMarkerRef.current = startMarker;

        // Calculate the destination coordinates as 10 meters away
        const destination = calculateDestinationTenMetersAway(position);

        // Add stop marker
        const stopMarker = L.marker([destination[0], destination[1]], {
          icon: stopIcon,
          draggable: true,
        }).addTo(map);

        stopMarkerRef.current = stopMarker;

        const routingControl = L.Routing.control({
          waypoints: [
            { latLng: L.latLng(position[0], position[1]) },
            { latLng: L.latLng(destination[0], destination[1]) },
          ],
          createMarker: function (i, waypoint, n) {
            const icon = i === 0 ? startIcon : stopIcon;
            return L.marker(waypoint.latLng, { icon: icon, draggable: true });
          },
        }).addTo(map);

        routingControlRef.current = routingControl;

        routingControl.on('waypointschanged', (e) => {
          const waypoints = e.waypoints.map((waypoint) => waypoint.latLng);
          startMarkerRef.current.setLatLng(waypoints[0]);
          stopMarkerRef.current.setLatLng(waypoints[waypoints.length - 1]);
        });
      }
    }
  }, [position]);

  function calculateDestinationTenMetersAway(currentCoordinates) {
    const earthRadius = 6371000;
    const bearing = Math.random() * 360;
    const distance = 10;

    const [latitude, longitude] = currentCoordinates.map((coord) => (coord * Math.PI) / 180);

    const newLatitude = Math.asin(
      Math.sin(latitude) * Math.cos(distance / earthRadius) +
        Math.cos(latitude) * Math.sin(distance / earthRadius) * Math.cos(bearing)
    );

    const newLongitude =
      longitude +
      Math.atan2(
        Math.sin(bearing) * Math.sin(distance / earthRadius) * Math.cos(latitude),
        Math.cos(distance / earthRadius) - Math.sin(latitude) * Math.sin(newLatitude)
      );

    return [newLatitude * (180 / Math.PI), newLongitude * (180 / Math.PI)];
  }


  const commonColumns = ['Sl. No', 'Vehicle', 'Driver', 'Contact', 'Local Body', 'Time'];


  return (
    <div style={{ backgroundColor: '#74C365', padding: '20px', minHeight: '200vh' }}>
      <Navbar bg="dark" variant="dark" style={{ width: '270vh', marginLeft: '-20px', marginTop: '-10px' }}>
        <Navbar.Brand href="#home">Waste Tracking</Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link href="https://busparrot.com/pis/gtfsmanager/">ROUTES</Nav.Link>
          <Nav.Link href="https://yatraparrot.com/home/db/taxiservice_action.php">VEHICLES</Nav.Link>
          <Nav.Link href="#trips">TRIPS</Nav.Link>
        </Nav>
      </Navbar>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontSize: '28px',
          color: 'white',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
          transition: 'color 0.3s',
        }}
      >
        <u> WASTE TRACKING INFO</u>
      </h1>
      <br />
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <label htmlFor="districtSelect" style={{ color: 'white' }}>
          Select District:
        </label>
        <select id="districtSelect" value={selectedDistrict} onChange={(e) => setSelectedDistrict(e.target.value)}>
          <option value="" disabled>
            Select District
          </option>
          {districts.map((district, index) => (
            <option key={index} value={district}>
              {district}
            </option>
          ))}
        </select>
      </div>
      <br />
      <br />
      <div id="map" style={{ width: '100%', height: '700px', margin: '20px auto' }} />




 {/* Tabs */}
 <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <div
          style={{
            cursor: 'pointer',
            padding: '10px 20px',
            backgroundColor: activeTab === 'route' ? '#222' : '#74C365',
            color: activeTab === 'route' ? '#74C365' : '#222',
            marginRight: '10px',
            borderRadius: '5px',
          }}
          onClick={() => handleTabClick('route')}
        >
          Route Violation
        </div>
        <div
          style={{
            cursor: 'pointer',
            padding: '10px 20px',
            backgroundColor: activeTab === 'time' ? '#222' : '#74C365',
            color: activeTab === 'time' ? '#74C365' : '#222',
            marginRight: '10px',
            borderRadius: '5px',
          }}
          onClick={() => handleTabClick('time')}
        >
          Time Violation
        </div>
        <div
          style={{
            cursor: 'pointer',
            padding: '10px 20px',
            backgroundColor: activeTab === 'geofencing' ? '#222' : '#74C365',
            color: activeTab === 'geofencing' ? '#74C365' : '#222',
            borderRadius: '5px',
          }}
          onClick={() => handleTabClick('geofencing')}
        >
          Geofencing Violation
        </div>
      </div>
      <CommonTable columns={commonColumns} />

      {/* Table for Violation Alerts */}
      <div style={{ display: activeTab === 'route' ? 'block' : 'none' }}>
        {/* Display Route Violation Table */}
        {/* You can create a table component or use a library like react-table here */}
        {/* Example: <RouteViolationTable /> */}
      </div>
      <div style={{ display: activeTab === 'time' ? 'block' : 'none' }}>
        {/* Display Time Violation Table */}
        {/* Example: <TimeViolationTable /> */}
      </div>
      <div style={{ display: activeTab === 'geofencing' ? 'block' : 'none' }}>
        {/* Display Geofencing Violation Table */}
        {/* Example: <GeofencingViolationTable /> */}
      </div>



    </div>
  );
};

export default Wastecollectionhome;
