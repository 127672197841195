import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navpage from './Navpage';
import busparroticon from '../Images/busparrotlogo.png';
import '../Styles/buses.css'; // Import your custom CSS file
import facebook from '../Images/facebook.png'
import linkedin from '../Images/linkedin.png'
import twitter from '../Images/twitter.png'
import youtube from '../Images/youtube.png'
import axios from 'axios';
import Footer from './Footer';
import Cookies from 'js-cookie'; 
const Buses = () => {
  const [activeButton, setActiveButton] = useState(null);
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);

    // Use navigate to go to the corresponding route
    switch (buttonName) {
      case 'button1':
        navigate('/');
        break;
      case 'button2':
        navigate('/trip');
        break;
      case 'button3':
        navigate('/route');
        break;
      case 'button4':
        navigate('/bus');
        break;
      default:
        break;
    }
  };


  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
          console.log(apiUrl, "kkkk");
  
          fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
              console.log('API Response:', data.stops[0]);
  
              if (data.stops.length > 0) {
                const firstStop = data.stops[0];
  
                setDefaultSelectValue({
                  value: firstStop.stop_id,
                  label: firstStop.stop_name,
                });
              }
            })
            
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
            fetchNearestStops(latitude,longitude );
        },
        (error) => {
          console.error('Error getting current position:', error);
          // If there's an error getting the current position, try to retrieve from cookies
          const latitudeFromCookie = Cookies.get('latitude');
          const longitudeFromCookie = Cookies.get('longitude');
  
          if (latitudeFromCookie && longitudeFromCookie) {
            const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitudeFromCookie}&lon=${longitudeFromCookie}&radius=2`;
  
            fetch(apiUrl)
              .then((response) => response.json())
              .then((data) => {
                console.log('API Response (from cookies):', data.stops[0]);
  
                if (data.stops.length > 0) {
                  const firstStop = data.stops[0];
  
                  setDefaultSelectValue({
                    value: firstStop.stop_id,
                    label: firstStop.stop_name,
                  });
                }
              })
              .catch((error) => {
                console.error('Error fetching data from cookies:', error);
              });
              fetchNearestStops(latitudeFromCookie, longitudeFromCookie);
          }
        }
      );
    
    } else {
      console.error('Geolocation is not available in your browser');
      // If geolocation is not available, try to retrieve from cookies
      const latitudeFromCookie = Cookies.get('latitude');
      const longitudeFromCookie = Cookies.get('longitude');
  
      if (latitudeFromCookie && longitudeFromCookie) {
        const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitudeFromCookie}&lon=${longitudeFromCookie}&radius=2`;
  
        fetch(apiUrl)
          .then((response) => response.json())
          .then((data) => {
            console.log('API Response (from cookies):', data.stops[0]);
  
            if (data.stops.length > 0) {
              const firstStop = data.stops[0];
  
              setDefaultSelectValue({
                value: firstStop.stop_id,
                label: firstStop.stop_name,
              });
            }
          })
          .catch((error) => {
            console.error('Error fetching data from cookies:', error);
          });
           fetchNearestStops(latitudeFromCookie, longitudeFromCookie);
      }
    }
  };
  
 
  const fetchNearestStops = async (latitude, longitude) => {
    try {
      console.log(latitude,longitude,"latlon");
      const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
      console.log(apiUrl,'stops')
      const response = await axios.get(apiUrl);
  
      console.log('API Response:', response);
  
      const nearestStopsData = response.data.stops.map((stop) => ({
        ID: stop.stop_id,
        stop_name: stop.stop_name,
        distance: stop.distance,
        lat: stop.stop_lat,
        lon: stop.stop_lon,
        lbcode:stop.stop_localbodycode,
        page:'bus',
      }));
  
      setNearestStops(nearestStopsData);
      setDefaultSelectValue({
        value: nearestStopsData[0].ID,
        label: nearestStopsData[0].stop_name,
      });
    } catch (error) {
      console.error('Error fetching nearest stops data:', error);
    }
  };
  

  useEffect(() => {
    getCurrentLocation(); // Fetch nearest stops when the component mounts
  }, []);


  return (
    <div>
      <Navpage />
      <div className='subbody container-fluid'>
        
      <div className="button-container">
        <button
            className={`button ${activeButton === 'button1' ? 'active' : ''}`}
            onClick={() => handleClick('button1')}
           
          >
            Stop
          </button>
          <button
            className={`button ${activeButton === 'button2' ? 'active' : ''}`}
            onClick={() => handleClick('button2')}
           
          >
            Trip
          </button>
          <button
            className={`button ${activeButton === 'button3' ? 'active' : ''}`}
            onClick={() => handleClick('button3')}
          >
            Route
          </button>
          
          <button
            className={`button ${activeButton === 'button4' ? 'active' : ''}`}
            onClick={() => handleClick('button4')}
            style={{ backgroundColor: '#76c045' }}
          >
            Bus
          </button>
        </div>
      </div>
      <div>
  {nearestStops.length > 0 && (
    <div>
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>
    </div>
  );
}

export default Buses;
