import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import axios from 'axios';
import '../Styles/details.css';
import like from '../Images/like.png';
import dislike from '../Images/dislike.png';
import greenbus from '../Images/greenbus.png';
import Footer from './Footer';
import Cookies from 'js-cookie';
import gmap from '../Images/gmap.png';
import sm from '../Images/streetview.png';
import vr from '../Images/vr.png';
const Stopbusdetails = () => {
  const location = useLocation();
  const { route, bus, id } = location.state;
  const [tripDetails, setTripDetails] = useState(null);
  const [currentTime, setCurrentTime] = useState('');
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);
  const [lastLocationLatitude1, setLastLocationLatitude1] = useState(null);
  const [lastLocationLongitude1, setLastLocationLongitude1] = useState(null);
  const [vrExists, setVrExists] = useState(false);
  const [vd, setVd] = useState(null);
  const divStyle = {
    textAlign: 'center',
    fontFamily: 'Comfortaa, sans-serif',
  };

  const handleclick = (trip) => {
    // Navigation logic
  };
  useEffect(() => {
    const fetchNearestStops = async (latitude, longitude) => {
      try {
        console.log(latitude, longitude, "latlon");
        const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
        console.log(apiUrl, 'stops');
        const response = await axios.get(apiUrl);
        console.log('API Response:', response);
        const nearestStopsData = response.data.stops.map((stop) => ({
          ID: stop.stop_id,
          stop_name: stop.stop_name,
          distance: stop.distance,
          lat: stop.stop_lat,
          lon: stop.stop_lon,
          lbcode: stop.stop_localbodycode,
          page:'stop',
        }));
        setNearestStops(nearestStopsData);
        setDefaultSelectValue({
          value: nearestStopsData[0].ID,
          label: nearestStopsData[0].stop_name,
        });
      } catch (error) {
        console.error('Error fetching nearest stops data:', error);
      }
    };
  
    const storedLatitude = Cookies.get('latitude');
    const storedLongitude = Cookies.get('longitude');
    setLastLocationLatitude1(storedLatitude);
    setLastLocationLongitude1(storedLongitude);
    console.log('Stored Latitude:', storedLatitude);
    console.log('Stored Longitude:', storedLongitude);
  
    if (storedLatitude && storedLongitude) {
      fetchNearestStops(storedLatitude, storedLongitude);
    } else {
      console.error('Latitude or longitude cookies are not set');
    }
  }, []);


  const handlemessage = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();
    const feedbackData = {
      appname: 'Bus',
      category: 'Stop',
      infoid: "tripid : " + trip.TripID,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'Correct',
      message: 'Not defined',
      time: currentTime.toLocaleString(),
    };

    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;

    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (response.status === 200) {
        window.alert('Feedback posted successfully!');
      } else {
        window.alert('Failed to post feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  const handlemessage1 = async (trip) => {
    const ipApiResponse = await axios.get('https://api.ipify.org?format=json');
    const userIpAddress = ipApiResponse.data.ip;
    const currentTime = new Date();

    const userMessage = window.prompt('Enter your feedback message:');

    if (userMessage === null) {
      window.alert('Feedback submission canceled.');
      return;
    }

    const feedbackData = {
      appname: 'Bus',
      category: 'Stop',
      infoid: 'tripid' + trip.TripID,
      customerid: 'Not defined',
      ipaddress: userIpAddress,
      feedback: 'InCorrect',
      message: userMessage || 'No Messages',
      time: currentTime.toLocaleString(),
    };

    const apiUrl = 'https://busparrot.com/feedback/customer_feedback.php';
    const urlWithParams = `${apiUrl}?appname=${feedbackData.appname}&category=${feedbackData.category}&infoid=${feedbackData.infoid}&customerid=${feedbackData.customerid}&ipaddress=${feedbackData.ipaddress}&feedback=${feedbackData.feedback}&message=${feedbackData.message}&time=${feedbackData.time}`;

    try {
      const response = await fetch(urlWithParams, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });

      if (response.status === 200) {
        window.alert('Feedback posted successfully!');
      } else {
        window.alert('Failed to post feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
  };

  const smallButtonStyle = {
    width: '40px',
  };

  const smallButtonStyle1 = {
    width: '30px',
    marginLeft: '10px',
    marginTop: '8px',
  };

  const arrivalStyle = {
    color: 'green',
    fontWeight: 'bold',
    fontSize: '3em',
    padding: '5px',
    borderRadius: '5px',
  };

  const departureStyle = {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '3em',
    padding: '5px',
    borderRadius: '5px',
  };

  const responsiveStyle = {
    '@media (max-width: 600px)': {
      arrivalStyle: {
        fontSize: '1em',
      },
      departureStyle: {
        fontSize: '1em',
      },
      smallButtonStyle: {
        width: '30px',
      },
      smallButtonStyle1: {
        width: '30px',
      },
    },
  };

  useEffect(() => {
    const apiUrl = `https://api.busparrot.com/gettripdetails.php?tripid=${id}`;

    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl);
        console.log('Trip details from stopbusdetails:', response.data);
        setTripDetails(response.data);
        if (response.data && response.data.result) {
          const results = response.data.result;

         
              const checkVrDirectories = async () => {
            const vrChecks = results.map(async (stop) => {
              const vrPath = `https://busparrot.com/home/db/check_vr.php?stop_id=${stop.stop_id}`;
          
              try {
                const vrResponse = await fetch(vrPath);
                const vrData = await vrResponse.json();
                return { stop_id: stop.stop_id, exists: vrData.exists };
              } catch (error) {
                console.error('Error checking VR directory:', error);
                return { stop_id: stop.stop_id, exists: false };
              }
            });
          
            const vrResults = await Promise.all(vrChecks);
            const vrStatus = vrResults.reduce((acc, { stop_id, exists }) => {
              acc[stop_id] = exists;
              return acc;
            }, {});
          
            // Filter and set only the true values
            const trueStatus = Object.entries(vrStatus)
              .filter(([_, exists]) => exists)
              .reduce((acc, [stop_id]) => {
                acc[stop_id] = true;
                return acc;
              }, {});
            console.log('True VR status:', trueStatus);
          
            // Save the trueStatus in setVrExists
            setVrExists(trueStatus);
          };
          
          checkVrDirectories();
        }


      } catch (error) {
        console.error('Error fetching trip details:', error);
      }
    };

    fetchData();
    const now = new Date();
    const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
    setCurrentTime(formattedTime);
  }, [id]);

  let foundFirstRedLine = false;
  const handleGetDirections = (trips) => {
 
    // Assuming you have the latitude and longitude values for the source and destination
 
    // Assuming you have the latitude and longitude values for the source and destination
    const destinationLat =trips.stop_lat;
    const destinationLng = trips.stop_lon;
console.log(destinationLat,destinationLng,lastLocationLatitude1,lastLocationLongitude1)
    // Create the Google Maps directions URL
 const directionsURL = `https://www.google.com/maps/dir/${lastLocationLatitude1},${lastLocationLongitude1}/${destinationLat},${destinationLng}`;

    // Open the URL in a new tab
   window.open(directionsURL);
    // const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${destinationLat},${destinationLng}&heading=0&pitch=0`;

    // Open the URL in a new tab
    // window.open(streetViewURL);
  };


  const handleGetDirections1 = (trips) => {
 
    // Assuming you have the latitude and longitude values for the source and destination
    const destinationLat =trips.stop_lat;
    const destinationLng = trips.stop_lon;
console.log(destinationLat,destinationLng,lastLocationLatitude1,lastLocationLongitude1)
    // Create the Google Maps directions URL
//  const directionsURL = `https://www.google.com/maps/dir/${lastLocationLatitude1},${lastLocationLongitude1}/${destinationLat},${destinationLng}`;

    // Open the URL in a new tab
  //  window.open(directionsURL);
     const streetViewURL = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${destinationLat},${destinationLng}&heading=0&pitch=0`;

    // Open the URL in a new tab
     window.open(streetViewURL);
  };

  const handleVRClick = (trips) => {
    console.log("trips", trips.stop_id);
  
    // Avoid triggering the request multiple times by checking if the state is already set
   
  
    const first_six = trips.stop_id.substring(0, 6);
    const filePath = `https://busparrot.com/pis/nbqr/uploadvr/${first_six}/${trips.stop_id}.jpeg`;
    setVd(filePath);
    console.log("vd inside",vd);
  };
  return (
    <div className='bg-body-tertiary mb-3'>
      <Details />
      <div style={divStyle} className="bg1">
        <h6 className='hstyle'>
          <b>{route}</b>
        </h6>
        <h6 className='hstyle1'>
          <b>{bus}</b>
        </h6>
      </div>
      <div>
        {tripDetails && tripDetails.success === 1 && (
          <div className="trip-list">
            {tripDetails.result.map((trips, index) => {
              const arrivalTime = trips.arrival_time;
              const isRedLine = arrivalTime >= currentTime;

              let showGreenBus = false;
              if (isRedLine && !foundFirstRedLine) {
                foundFirstRedLine = true;
                showGreenBus = true;
              }

              return (
                <div key={trips.TripID} style={{
                  position: 'relative',
                  marginTop: index === 0 ? '10px' : '0',
                }}>
                  <div style={{
                    borderLeft: arrivalTime < currentTime ? '8px solid green' : '8px solid red',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    paddingLeft: '30px',
                    position: 'relative',
                    marginLeft:'10px'
                  }}>
   
  {!showGreenBus && (
    <div style={{
      width: '15px',
      height: '10px',
      borderRadius: '20%',
      backgroundColor: arrivalTime < currentTime ? 'green' : 'red',
      position: 'absolute',
       left: '0px',
      top: '50%',
      transform: 'translateY(-50%)',
    }}></div>
  )}

  {showGreenBus && (
    <img src={greenbus} alt="Green Bus" style={{
      position: 'absolute',
      left: '5px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '25px',
      height: '30px'
    }} />
  )}

                    
                    <div className='det' style={{ flex: 1 }}>
                      <div className='trip-details'>
                        <br />
                        <p style={{ ...arrivalStyle, ...responsiveStyle['@media (max-width: 600px)'].arrivalStyle }}>
                          <span style={{ fontSize: '2em', fontWeight: 'bold', color: 'green' }}>{trips.arrival_time}</span> <b>Arrival</b>
                        </p>
                        <h6><b>{trips.stop_name}</b></h6>
                        <span className='sp' onClick={() => handleclick(trips)}><b>{bus}</b></span>
                        <p style={{ ...departureStyle, ...responsiveStyle['@media (max-width: 600px)'].departureStyle }}>
                          <span style={{ fontSize: '2em', fontWeight: 'bold', color: 'red' }}>{trips.departure_time} </span> <b>Departure</b>
                        </p>
                        <div className='container-style'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
 
                        <a onClick={() => handleGetDirections(trips)}>
                          <img
                            src={gmap}
                            alt="Google Maps Icon"
                            style={{ width: '20px', height: '20px', marginRight: '10px' }}
                          />
                        </a>
                        <span className="go">Go </span>
                        <a onClick={() => handleGetDirections1(trips)}>
                          <img
                            src={sm}
                            alt="Google Maps Icon"
                            style={{ width: '35px', height: '30px', marginRight: '10px',marginLeft:'10px' }}
                          />
                        </a>
                        {vrExists[trips.stop_id] && (
    <img
      src={vr}
      alt="VR"
      style={smallButtonStyle1}
      onClick={() => handleVRClick(trips)}
    />
  )}
  {vrExists[trips.stop_id] && vd ? (
    <a-scene embedded style={{ width: '20px', height: '20px', marginLeft: '8px' }}>
      <a-sky src={vd}></a-sky>
    </a-scene>
  ) : null}
</div>
       <br/>
                          <h7><b>Was this Correct?</b></h7>
                          <div onClick={() => handlemessage(trips)} style={{ display: 'inline-block' }}>
                            <img src='https://busparrot.com/assets/like.png' alt='Correct' style={{ ...smallButtonStyle, ...responsiveStyle['@media (max-width: 600px)'].smallButtonStyle }} />
                          </div>
                          <div onClick={() => handlemessage1(trips)} style={{ display: 'inline-block' }}>
                            <img src='https://busparrot.com/assets/dislike.png' alt='Incorrect' style={{ ...smallButtonStyle1, ...responsiveStyle['@media (max-width: 600px)'].smallButtonStyle1 }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div>
  {nearestStops.length > 0 && (
    <div >
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>
    </div>
  );
};

export default Stopbusdetails;
