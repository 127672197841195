
import './App.css';
import Main from './Components/Main'
function App() {
  return (
    <div >
    <Main/>
      
    </div>
  );
}

export default App;
