import React, { useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navpage from './Navpage';
import busparroticon from '../Images/busparrotlogo.png';
import '../Styles/buses.css'; // Import your custom CSS file

import facebook from '../Images/facebook.png'
import linkedin from '../Images/linkedin.png'
import twitter from '../Images/twitter.png'
import youtube from '../Images/youtube.png'

import Select from 'react-select';
import { css } from '@emotion/react';
import Map from './Map';
import Cookies from 'js-cookie'; 
import axios from 'axios';
import Footer from './Footer';
const Route1 = () => {
  const [typedInput, setTypedInput] = useState('');
  const [activeButton, setActiveButton] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedStop, setSelectedStop] = useState('');
  const [stopOptions, setStopOptions] = useState([]);
  const [nearestStops, setNearestStops] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState(null);

  const navigate = useNavigate(); // Initialize useNavigate

const nav =useNavigate();
  
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '300px', // Set the width to your desired value
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black', // Set the text color to black
      width: '300px',
    }),
  };

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);

    // Use navigate to go to the corresponding route
    switch (buttonName) {
      case 'button1':
        navigate('/');
        break;
      case 'button2':
        navigate('/trip');
        break;
      case 'button3':
        navigate('/route');
        break;
      case 'button4':
        navigate('/bus');
        break;
      default:
        break;
    }
  };
  

  const handleSelectChange = (selectedOption) => {
    setSelectedStop(selectedOption);

  };
  const fetchStops = (inputValue) => {
    // Replace with your API URL
    const apiUrl = `https://api.busparrot.com/searchroute.php?term=${inputValue}`;

    // Fetch data from the API
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        const options = data.map((stop) => ({
          value:stop.id,
          label: stop.value,
        }));
        const allOptions = [
          ...options,
          { value: 'IN000015', label: 'GOVINDAPURAM-THRISSUR via NENMARA(IN000015)' },
          { value: 'IN000022', label: 'Nenmara- Palakkad via Koduvayur(IN000022)' },
          { value: 'IN000021', label: 'NENMARA-ALATHUR VIA CHITTILLANCHERRI(IN000021)' },
          { value: 'IN000024', label: 'Nenmara-Olippara via adiperanda(IN000024)' },
          { value: 'IN000003', label: 'NENMARA-PALAKKAD VIA KODUVAYUR(IN000003)' },
          { value: 'IN000002', label: 'PALAKKAD-NENMARA VIA KUNISSERRI(IN000002)' },
         
        ];

        setStopOptions(allOptions);
        // setStopOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

 
  const handleSearch = () => {
    // Replace with your search logic using the selected stop (selectedStop.value)
    if (selectedStop) {
      console.log('Searching for stop:', selectedStop);
      nav('/routedetails', { state: { route: selectedStop } })
    } else {
      console.log('Please select a stop to search.');
    }
  };
console.log(selectedStop)



const getCurrentLocation = () => {
    
  // console.error('Geolocation is not available in your browser');
  // If geolocation is not available, try to retrieve from cookies
  const latitudeFromCookie = Cookies.get('latitude');
  const longitudeFromCookie = Cookies.get('longitude');

  if (latitudeFromCookie && longitudeFromCookie) {
    const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitudeFromCookie}&lon=${longitudeFromCookie}&radius=2`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log('API Response (from cookies):', data.stops[0]);

        if (data.stops.length > 0) {
          const firstStop = data.stops[0];

          setDefaultSelectValue({
            value: firstStop.stop_id,
            label: firstStop.stop_name,
          });
          
        }
           
      })
      .catch((error) => {
        console.error('Error fetching data from cookies:', error);
      });
  }

};

useEffect(() => {
getCurrentLocation(); // Fetch nearest stops when the component mounts
}, []);



useEffect(() => {
  const fetchNearestStops = async (latitude, longitude) => {
    try {
      console.log(latitude, longitude, "latlon");
      const apiUrl = `https://api.busparrot.com/getneareststops.php?lat=${latitude}&lon=${longitude}&radius=2`;
      console.log(apiUrl, 'stops');
      const response = await axios.get(apiUrl);
      console.log('API Response:', response);
      const nearestStopsData = response.data.stops.map((stop) => ({
        ID: stop.stop_id,
        stop_name: stop.stop_name,
        distance: stop.distance,
        lat: stop.stop_lat,
        lon: stop.stop_lon,
        lbcode: stop.stop_localbodycode,
        page:'route',
      }));
      setNearestStops(nearestStopsData);
      setDefaultSelectValue({
        value: nearestStopsData[0].ID,
        label: nearestStopsData[0].stop_name,
      });
    } catch (error) {
      console.error('Error fetching nearest stops data:', error);
    }
  };

  const storedLatitude = Cookies.get('latitude');
  const storedLongitude = Cookies.get('longitude');

  console.log('Stored Latitude:', storedLatitude);
  console.log('Stored Longitude:', storedLongitude);

  if (storedLatitude && storedLongitude) {
    fetchNearestStops(storedLatitude, storedLongitude);
  } else {
    console.error('Latitude or longitude cookies are not set');
  }
}, []);

  return (
    <div>
   <Navpage/>

   <div className='subbody container-fluid'>
   
  
   <div className="button-container">
        <button
            className={`button ${activeButton === 'button1' ? 'active' : ''}`}
            onClick={() => handleClick('button1')}
           
          >
            Stop
          </button>
          <button
            className={`button ${activeButton === 'button2' ? 'active' : ''}`}
            onClick={() => handleClick('button2')}
           
          >
            Trip
          </button>
          <button
            className={`button ${activeButton === 'button3' ? 'active' : ''}`}
            onClick={() => handleClick('button3')}
            style={{ backgroundColor: '#76c045' }}
          >
            Route
          </button>
          
          <button
            className={`button ${activeButton === 'button4' ? 'active' : ''}`}
            onClick={() => handleClick('button4')}
          >
            Bus
          </button>
        </div>

        <div className="trip-container">
          <h2>Search Routes</h2>
          <div className="input-container">
            {/* Searchable select box */}
            <Select    // Apply the width styles
  value={selectedStop}
  onInputChange={(inputValue) => {
    setTypedInput(inputValue);
    fetchStops(inputValue);
  }}
  onChange={(value) => setSelectedStop(value)}
  options={stopOptions}
  placeholder="Select Routes"
  defaultValue={null}
  styles={customStyles} // Apply the color styles
            />
          </div>

          <div className="input-container">
            <button className="search-button" onClick={handleSearch}>
              Search
            </button>
          </div>

      

          {/* Display search results */}
          <div className="search-results">
            {searchResults.map((result) => (
              <div key={result.id} className="search-result">
                <p>Route Name: {result.name}</p>
                <p>Route Location: {result.location}</p>
                {/* Display other properties from the search result as needed */}
              </div>
            ))}
          </div>
        </div>
        
      </div>
      <div className='mapstyle'><Map/></div>
    <br/>
    <div style={{textAlign:'center',marginBottom:'750px'}}>
    <a href='https://m.facebook.com/100063656121677/'><img className="imagesocial" style={{width:'30px'}} src={facebook} alt=""></img></a>
<a href='https://twitter.com/BusParrot?s=08'><img className="imagesocial" style={{width:'30px'}} src={twitter} alt=""></img></a>
<a href='https://youtube.com/@user-mk1uk6sq2t'><img className="imagesocial" style={{width:'30px'}} src={youtube} alt=""></img></a>
<a href='https://www.linkedin.com/company/busparrot/'><img className="imagesocial" style={{width:'30px'}} src={linkedin} alt=""></img></a>
</div>


<div>
  {nearestStops.length > 0 && (
    <div>
      <Footer nearestStops={nearestStops} />
    </div>
  )}
</div>


    </div>
  );
}

export default Route1;
